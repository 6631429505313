import React, { useEffect, useState }  from "react";
import "./userlist.scss";
import translate from "../../../utils/locale/language";
import CustomTable from "../../../components/customtable/customtable";
import CreateUserForm from "./createuserform/createuserform";
import { getMethod } from "../../../utils/apis/services/api";
import urls from "../../../utils/apis/services/apiurls.json";
import LoadingOverlay from 'react-loading-overlay';

   function UserList() {

    const [userslist, setUserslist] = useState([]);
    const [section,setSection]=useState(2)
    const[searchterm,setsearch]=useState('')
    const [loader,setLoader]=useState(false)
    const [selectedtab,setTab]=useState("active")

    const tabs=[
      {
          tab:"Active",
          slug:"active"
      },        
      {
          tab:"Inactive",
          slug:"inctive"
      },      
      {
          tab:"Unsubscribed",
          slug:"unsubscribed"
      }
  ]
    
    const getusersList=async(type)=>{
        setLoader(true)
        let userslist=await getMethod(urls.userslist+"usertype="+type)
        setUserslist(userslist&&userslist.data?userslist.data:[])
        setLoader(false)
      }

    const getSearch=async(term)=>{
        setLoader(true)
        let userslist=await getMethod(urls.userslist+"usertype="+selectedtab+"&search="+term)
        setUserslist(userslist&&userslist.data?userslist.data:[])
        setLoader(false)
      }

  //  useEffect(()=>{
  //       getusersList(selectedtab)
  //   },[])

    useEffect(()=>{
      getusersList(selectedtab)
     },[selectedtab])

     useEffect(()=>{
      searchterm.length>0&&getSearch(searchterm)
     },[searchterm])

   
    return (

      <div className="userlist">
      <LoadingOverlay
      active={loader}
      spinner
      text={translate("loadertext")}
      >
      <div className="pageheading" >

      <h2 >{translate("userlist")}</h2>

      <div className="mainbuttons" >
       
       <button className="create" onClick={()=>setSection(1)}>{translate("create")}</button>
       <button className="bulk" onClick={()=>setSection(2)}>{translate("bulk")}</button>

      </div>
      </div> 

      {section===2&&<div className="tabs">
               <ul>
                   {
                       tabs.map((item)=>{
                       return <li onClick={()=>setTab(item.slug)}><span className={item.slug===selectedtab?"active":""}>{item.tab}</span></li>
                       })
                   }

                   <input placeholder="search..." className="search" onChange={(e)=>setsearch(e.target.value)}/>
               </ul>
      </div>} 

      <div className="userlisttable">

       {
         
         section===2
         ?
         <CustomTable data={userslist}/>
         :
        <CreateUserForm/>
        }

      </div>
      </LoadingOverlay>

      </div>
    );
   }

   export default UserList ;