import React, { useCallback, useEffect, useState } from "react";
import "./posttrackep.scss";
import translate from "../../utils/locale/language";
import { Line } from "rc-progress";
import { getMethod, getMethodOpen, postMethodAuthenticated ,postMethodAuthenticatedProgress} from "../../utils/apis/services/api";
import urls from "../../utils/apis/services/apiurls.json";
import { toast } from "react-toastify";
import Select from 'react-dropdown-select';
import musicplus from '../../assets/images/posttrack/musicplus.svg';
import titleicon from '../../assets/images/posttrack/title.svg';
import uploadimg from '../../assets/images/posttrack/uploadimg.svg';
import uploadsymbol from '../../assets/images/posttrack/uploadsymbol.svg';
import addphotoplaceholder from '../../assets/images/posttrack/addphotoplaceholder.png';
import Dropzone from "react-dropzone";
import LoadingOverlay from 'react-loading-overlay';
import { useHistory } from "react-router";
import userpalceholder from "../../assets/images/dashboard/userplaceholder.jpg"
import moment from "moment";

function PostTrackandEP() {

  const history=useHistory()

  const [selectedbox, setSelectedBox] = useState("track");
  const [track, setTrack] = useState(null);
  const [uploadpercentage,setStateUploadPercentage]=useState(0)
  const [episodestracks, setEpisodesTracks] = useState([]);
  const [title, setTitle] = useState("");
  const [label, setLabel] = useState([]);
  const [cover_image, setCover_image] = useState(null);
  const [coverimg, setCoverimg] = useState(null);
  const [genrelist,setGenreList] = useState([])
  const [subgenrelist,setSubGenre] = useState([])
  const [genre, setGenre] = useState("");
  const [description, setDescription] = useState("");
  const [ep1,setEpisode1] = useState(null)
  const [ep2,setEpisode2] = useState(null)
  const [ep3,setEpisode3] = useState(null)
  const [ep4,setEpisode4] = useState(null)
  const [postracksuccess, setPostTrackSuccess] = useState(false);
  const [postepsuccess, setPostEPSuccess] = useState(false);
  const [title1, setTitle1] = useState("");
  const [title2, setTitle2] = useState("");
  const [title3, setTitle3] = useState("");
  const [title4, setTitle4] = useState("");
  const [loader,setLoader]=useState(false)
  const [showlabel,setShowLabel]=useState(false)
  const[searchterm,setsearch]=useState('')
  const[selectedlabels,setSelectedlabels]=useState([])
  const[labeloptions,setLabeloptions]=useState([])


  const getGenreList=async()=>{
    let genrelist=await getMethodOpen(urls.genrelist)
    setGenreList(genrelist&&genrelist.data?genrelist.data:[])
  }

  const getLabelList=async()=>{
    let labellist=await getMethod(urls.getlabellist,"")
    setLabeloptions(labellist&&labellist.data?labellist.data:[])
  }

  useEffect(()=>{
   getGenreList()
   getLabelList()
  },[])

const onDropTrack = useCallback(acceptedFiles => {
    acceptedFiles.map(file => {
        console.log(file)
        handleFiles(file, "track")
    })
    }, []);


const onDropEP1 = useCallback(acceptedFiles => {
    acceptedFiles.map(file => {
      let duration;
       let mySound = new Audio([URL.createObjectURL(file)]);
        mySound.oncanplaythrough=function(){
            duration=moment.utc(this.duration*1000).format('mm:ss')
            let obj={
              file:file,
              duration:duration
            }
            episodestracks.splice(0,1,obj)
        }
    handleFiles(file, "ep1")

     })
    }, []);

const onDropEP2 = useCallback(acceptedFiles => {
      acceptedFiles.map(file => {
        let duration;
        let mySound = new Audio([URL.createObjectURL(file)]);
         mySound.oncanplaythrough=function(){
             duration=moment.utc(this.duration*1000).format('mm:ss')
             let obj={
               file:file,
               duration:duration
             }
             episodestracks.splice(1,1,obj)
         }
        handleFiles(file, "ep2")
       })
      }, []);
      
const onDropEP3 = useCallback(acceptedFiles => {
        acceptedFiles.map(file => {
          let duration;
          let mySound = new Audio([URL.createObjectURL(file)]);
           mySound.oncanplaythrough=function(){
               duration=moment.utc(this.duration*1000).format('mm:ss')
               let obj={
                 file:file,
                 duration:duration
               }
               episodestracks.splice(2,1,obj)
           }
            handleFiles(file, "ep3")
         })
        }, []);

const onDropEP4 = useCallback(acceptedFiles => {
        acceptedFiles.map(file => {
          let duration;
          let mySound = new Audio([URL.createObjectURL(file)]);
           mySound.oncanplaythrough=function(){
               duration=moment.utc(this.duration*1000).format('mm:ss')
               let obj={
                 file:file,
                 duration:duration
               }
               episodestracks.splice(3,1,obj)
           }     
           handleFiles(file, "ep4") 
         })
     }, []);


  const onUploadProgress=(progressEvent) => {
    const {loaded, total} = progressEvent;
    let percent = Math.floor( (loaded * 100) / total )
    setStateUploadPercentage(percent)
  }


  const handleFiles = async (e, type) => {

    let formData = new FormData();
    if (type === "previewimage") {
      formData.append("file", e.target.files[0]);
      var img = new Image();
      img.src = window.URL.createObjectURL(e.target.files[0]);
      img.onload = async function() 
      {
          let width = this.naturalWidth;
          let height = this.naturalHeight;
          if(width>=600&&height>=300)
          {
            let result = await postMethodAuthenticated(urls.fileupload, formData);
            console.log("result file uplaod", result.data.id);
            let previewimg = result.data.id;
            setCover_image(previewimg);
            setCoverimg(e.target.files[0]);
          }
          else{
            toast.error(translate("uploadproperresolutionimage"))
          }

      };
    } 
    else if (type === "track") {
      formData.append("file", e);
      let result = await postMethodAuthenticatedProgress(urls.fileupload, formData,onUploadProgress);
      console.log("result file uplaod", result.data.id);
      let track = result.data.id;
      setTrack(track);
    } 

    else if(type==="ep1")
    {
      formData.append("file", e);
      let result = await postMethodAuthenticatedProgress(urls.fileupload, formData,onUploadProgress);
      let episode = result.data.id;
      setEpisode1(episode); 
    }
    else if(type==="ep2")
    {
      formData.append("file", e);
      let result = await postMethodAuthenticatedProgress(urls.fileupload, formData,onUploadProgress);
      let episode = result.data.id;
      setEpisode2(episode); 
    }
    else if(type==="ep3")
    {
      formData.append("file", e);
      let result = await postMethodAuthenticatedProgress(urls.fileupload, formData,onUploadProgress);
      let episode = result.data.id;
      setEpisode3(episode); 
    }
    else if(type==="ep4")
    {
      formData.append("file", e);
      let result = await postMethodAuthenticatedProgress(urls.fileupload, formData,onUploadProgress);
      let episode = result.data.id;
      setEpisode4(episode); 
    }
  };

  const handleSubgenre =(values)=>{
    setSubGenre(values.map((item)=>{return item.slug}));
  }
  
  const PostTrackSuccess=()=>{
    setLoader(false)
    toast.success(translate("trackuploadsuccess"));
    setPostTrackSuccess(true)
  }

  const PostEpSuccess=()=>{
    setLoader(false)
    toast.success(translate("epuploadsuccess"));
    setPostEPSuccess(true)
  }

  const handleSubmit = async () => {
    let labels=selectedlabels.map((item)=>{return item.id});
    let body = {
      description: description,
      label:labels,
      genre: genre,
      subgenre:subgenrelist,
      cover_image: cover_image,
    };
    let trackId = {
       track: track,
       title: [title]
    }
    let episodeId = {
      episode: [
        {episode:ep1&&ep1,title:title1},
        {episode:ep2&&ep2,title:title2},
        {episode:ep3&&ep3,title:title3},
        {episode:ep4&&ep4,title:title4}]
    }
    if(track !== null)
    {
      Object.assign(body, trackId);
    }
    if(track===null)
    {
      Object.assign(body, episodeId);
    }
    console.log(body)
    setLoader(true)
    let result = await postMethodAuthenticated(urls.posttrackep, body);
    console.log("result", result);
    if(!result.data.Error&&result.status===200)
    {
    result.data.module_name==="track" ? 
    PostTrackSuccess() 
    : PostEpSuccess();
    }
    else{
      setLoader(false)
      toast.error(result.data.Error)
    }
  };

  let labelSearchResult = labeloptions.filter(label => {
    return label.username.toLowerCase().includes(searchterm)
    })

  const addLabel=(item,index)=>{
      selectedlabels.push(item)
      labeloptions.splice(index,1)
      setShowLabel(!showlabel)
  }

  const labelstags=(item)=>{
    return <>
    <img alt="" className="customlabelimage" src={item.profile_image?item.profile_image:userpalceholder}></img>
    </>
  }

  const uploadanothertrack=()=>{
    setPostTrackSuccess(false);
    setTrack(null);
    setCover_image(null);
    setCoverimg(null);
    history.push("/postatrack")
  }
 
  return (
    <div className="postTrackEp">
      <h2 className="mainheading">
        <img src={musicplus} alt="" />
        {translate("postatrack")}
      </h2>

      {
       (!postracksuccess&&!postepsuccess)?
       <LoadingOverlay
       active={loader}
       spinner
       text={translate("loadertext")}
       >
       <div>
          <div className="uploadtrackorep">
        <div
          onClick={() => setSelectedBox("track")}
          className={
            selectedbox === "track"
              ? "selecteduploadbox"
              : "unselecteduploadbox"
          }
        >
          {
          selectedbox !== "track" ?
           (
            <span>
              <i class="far fa-music"></i>
              <p>{translate("postatrack")}</p>
            </span>
          )
           : 
          (
            <span className="uploadeptrack">
              <Dropzone onDrop={onDropTrack} accept="audio/*">
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps() }>
              <label className="custom-file-upload">
                <input
                  {...getInputProps()}
                />
                <i class="fas fa-upload" />
                {translate("uploadaudio")}
              </label>
              <p>{translate("drangdrophere")}</p>
              </div>
              )}
              </Dropzone>
            </span>
          )
          }
        </div>

        <div
          onClick={() => {setSelectedBox("ep");setTrack(null)}}
          className={
            selectedbox === "ep" ? "selecteduploadboxEP" : "unselecteduploadbox"
          }
        >
          {selectedbox !== "ep" ? (
            <span>
              <i class="far fa-list"></i>
              <p>{translate("postep")}</p>
            </span>
          ) : (
            <span className="uploadeptrackEP">


              <div style={{width:"100%"}}>

              <div className="epblocks"  >
               <Dropzone onDrop={onDropEP1} accept="audio/*" >
              {({ getRootProps, getInputProps }) => (
                <div>
               <label className="custom-file-upload" {...getRootProps() }>
                <input
                  {...getInputProps()}
                />
                <i class="fas fa-upload" />
                {translate("uploadepep")}
              </label>
              <p className="dragndropep" >{translate("drangdrophere")}</p>
              <p className="epno">1</p>
              </div>
               )}
               </Dropzone>
               </div>

               <div className="epblocks"  >
               <Dropzone onDrop={onDropEP2} accept="audio/*" disabled={episodestracks.length<1&&true}>
              {({ getRootProps, getInputProps }) => (
                <div>
               <label className="custom-file-upload" {...getRootProps() }>
                <input
                  {...getInputProps()}
                  disabled={episodestracks.length<1&&true}
                />
                <i class="fas fa-upload" />
                {translate("uploadepep")}
              </label>
              <p className="dragndropep" >{translate("drangdrophere")}</p>
              <p className="epno">2</p>
              </div>
               )}
               </Dropzone>
               </div>

               </div>


               <div style={{width:"100%"}}>

               <div className="epblocks"  >
               <Dropzone onDrop={onDropEP3} accept="audio/*" disabled={episodestracks.length<2&&true}>
              {({ getRootProps, getInputProps }) => (
                <div>
               <label className="custom-file-upload" {...getRootProps() }>
                <input
                  {...getInputProps()}
                  disabled={episodestracks.length<2&&true}
                />
                <i class="fas fa-upload" />
                {translate("uploadepep")}
              </label>
              <p className="dragndropep" >{translate("drangdrophere")}</p>
              <p className="epno">3</p>
              </div>
               )}
               </Dropzone>
               </div>


               <div className="epblocks"  >
               <Dropzone onDrop={onDropEP4} accept="audio/*" disabled={episodestracks.length<3&&true}>
              {({ getRootProps, getInputProps }) => (
                <div>
               <label className="custom-file-upload" {...getRootProps() }>
                <input
                  {...getInputProps()}
                  disabled={episodestracks.length<3&&true}
                />
                <i class="fas fa-upload" />
                {translate("uploadepep")}
              </label>
              <p className="dragndropep" >{translate("drangdrophere")}</p>
              <p className="epno">4</p>
              </div>
               )}
               </Dropzone>
               </div>

             </div>

            </span>
          )}
        </div>
      </div>

      <div className="trackepprogress" style={{display:"flex"}}>
        <Line
          className="stroke"
          percent={uploadpercentage}
          strokeWidth="10"
          trailWidth="10"
          trailColor="transparent"
          strokeColor="#479898"
        />
        <span>{uploadpercentage}%</span>
      </div>
      {
        selectedbox === "ep"?

       <div>
       {
         episodestracks.map((item,index)=>{
          return <div className="eptrackscontainer">
          <div className="eptrackstitle">
             <p className="dashline">{"---"+index}</p>
             <p style={{display:"flex"}}>{translate("filename")}:<p className="detail">{item.file.name}</p></p>
             <p style={{marginLeft:"auto",display:"flex"}}>{translate("duration")}:<p className="detail">{item.duration}</p></p>
           </div>
           <div className="tracktitleep">
           <span>
             <img src={titleicon} alt="" />
             <p>{translate("title")}</p>
           </span>
           <input
             accept="image/*"
             type="text"
             placeholder={translate("addtracktitle")}
             name="title"
             onChange={(e) => {index===0?setTitle1(e.target.value):index===1?setTitle2(e.target.value):index===2?setTitle3(e.target.value):setTitle4(e.target.value)}}
           ></input>
          </div>
         </div>
         })
       }
      </div>
      :
        <div className="tracktitle">
        <span>
          <img src={titleicon} alt="" />
          <p>{translate("title")}</p>
        </span>
        <input
          type="text"
          placeholder={translate("addtracktitle")}
          name="title"
          onChange={(e) => setTitle(e.target.value)}
        ></input>
      </div>
      }

      <div style={{display:"flex"}}>

      <div className="genretags">
        <span className="genreimg">
          <p>{translate("genre")+": "}</p>
        </span>
        <div>
        <Select  options={genrelist} labelField={"genre_name"} valueField={"slug"} onChange={(values) =>setGenre(values[0].slug)} className="ReactDropdownSelect" />
        </div>
      </div>

     <div className="subgenretags">
        <span className="genreimg">
          <i class="far fa-tag"></i>
          <p>{translate("subgenre")}</p>
        </span>
        <div>
        <Select multi options={genrelist} labelField={"genre_name"} valueField={"slug"} onChange={(values)=>handleSubgenre(values)} className="ReactDropdownSelect" />
        </div>
      </div>

      </div>


      <div className="monitoring">
           <div>
           <h3>{translate("labels")}</h3>
           <p>{translate("monitoringsubheading")}</p>
           </div>
          <div className="monitoringfield">
             {
               selectedlabels.map((item)=>{
                return  labelstags(item)
               })
              }
         {selectedlabels.length<6&&<span className="customlabels" onClick={()=>setShowLabel(!showlabel)}>+</span>}
          <div  class={showlabel?"dropdown-content show":"dropdown-content"} >
            <input type="text" placeholder="Search..."  onChange={(e)=>setsearch(e.target.value)} />
            {labelSearchResult.map((item,index)=>{return <p onClick={()=>addLabel(item,index)}>{item.username}</p>})}
          </div>
          </div>
        </div>

      <hr className="trackhrline" />

      <div className="previewimage">
        <h3>
          {translate("uploadimage")}
          <img src={uploadimg}  alt=""/>
        </h3>
        <p>{translate("selectimagetrack")}</p>

        <div className="uploadtrackimage">
          <label className="uploadbox">
            <input
              id="custompreview"
              className="uploadbox"
              type="file"
              onChange={(e) => handleFiles(e, "previewimage")}
            />
            <div>
            <img src={uploadsymbol} alt=""/>
            <p className="uploadcoverimagevalid">{translate("uploadcoverimage")}</p>
            </div>

          </label>

          <div className="previewimg">
            <p>{translate("preview") + ":-"}</p>
            <img
              src={coverimg ? URL.createObjectURL(coverimg) : addphotoplaceholder}
              alt=""
            />
          </div>
        </div>

        <hr className="trackhrline" />

        <div className="postonyourprofile">
          <h3>{translate("postonprofile")}</h3>
          <p>{translate("commentontrack")}</p>

          <div className="additionaldescription">
            <p>
              <i class="fas fa-text"></i>
              {translate("additionaldescription")}
            </p>
            <textarea
              placeholder={translate("writedescription")}
              name="additionaldesc"
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>
          </div>
        </div>

        <div>
          <button className="postTrackbutton" onClick={handleSubmit}>
            {translate("upload")}
          </button>
        </div>
      </div>
       </div>
       </LoadingOverlay>
      :
      <div>
        {
          postracksuccess
          ?
          <div  className="tracksuccesfull">
          <h2>{translate("done")}</h2>
          <p>{translate("yourtrackbeenuploaded")}</p>
          <div className="buttons">
            <button className="button1" onClick={()=>uploadanothertrack()}>{translate("uploadnextrack")}</button>
            <p style={{alignSelf:"center"}}>or</p>
            <button className="button2" onClick={()=>history.push("/home")}>{translate("getmetodashboard")}</button>
          </div>
        </div>
        :
        <div  className="tracksuccesfull">
        <h2>{translate("done")}</h2>
        <p>{translate("yourepbeenuploaded")}</p>
        <div className="buttons">
          <button className="button2" onClick={()=>history.push("/home")}>{translate("getmetodashboard")}</button>
        </div>
      </div>
      }
      </div>
      }
 
     
    </div>
  );
}

export default PostTrackandEP;
