import React, { useState }  from "react";
import "./customtable.scss";
// import translate from "../../utils/locale/language";
import moment from "moment";
import Select from 'react-dropdown-select';

function CustomTable(props) {


  const [edituser,setEdituser]=useState(null)
  // const [genre, setGenre] = useState("");

return(
    <table className="customtable">

      <thead>
      <tr>
      <th style={{width:"20%"}}>{"Profile Image"}</th>
      <th style={{width:"35%"}}>{"Name"}</th>
      <th style={{width:"30%"}}>{"Email"}</th>
      <th style={{border:0}}>{"Subscription Date"}</th>
    </tr>
      </thead>


    <tbody>

   {
       props.data.map((item,index)=>{
           return <><tr onClick={()=>setEdituser(index)}>

           <td className="rowdata1">
            <img src={item.profile_image} alt="" />
           </td>
           <td className="rowdata2">{item.full_name}</td>
           <td className="rowdata3">{item.email}</td>
           <td className="rowdata4">{moment(item.date_joined).format("DD/M/yyyy")}</td>
         </tr>
         {/* {
           (edituser===index)&&<div className="edituserlist">
             
            <div className="heading">
            <p>{translate("quickedit")}<b>{"Daxj"+index} </b>{ translate("profile")}</p>
             <button className="savebutton" onClick={()=>setEdituser(null)}>{translate("save")}</button>
            </div>

            <div className="edituserdetails">

              <div className="imageselector">
              </div>

              <div className="edituserfields">

              <div className="textfieldedituser">
                <label>{translate("artist")}</label>
                <span className="divider"></span>
                <input placeholder="Enter artist name" defaultValue={"Daxj"+index}></input>
              </div>

              <div className="flexrows">

              <div className="textfieldedituser">
                <label>{translate("city")}</label>
                <span className="divider"></span>
                <input placeholder="Enter city" defaultValue={"Italy"}></input>
              </div>

              <div className="textfieldedituser">
                <label>{translate("soundcloud")}</label>
                <span className="divider"></span>
                <input placeholder="Enter soundcloud Id" defaultValue={"@Daxj"+index}></input>
              </div>

              </div>

              <div className="flexrows">

              <div className="textfieldedituser">
                <label>{translate("age")}</label>
                <span className="divider"></span>
                <input placeholder="Enter age" defaultValue={18}></input>
              </div>

              <div className="genretags">
               <span className="genreimg">
               <p>{translate("maingenre")+": "}</p>
               </span>
                <div>
                 <Select options={[]} labelField={"genre_name"} valueField={"slug"} onChange={(values) =>setGenre(values[0].slug)} className="ReactDropdownSelect" />
                </div>
             </div>

              </div>

              </div>

            </div>

         </div>
         } */}
         </>
       })
   }


    </tbody>


   </table>
)

}
export default CustomTable ;