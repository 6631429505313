import React, { useEffect, useState }   from "react";
import "./royalties.scss";
import translate from "../../../utils/locale/language";
import moment from "moment";
import { getMethod } from "../../../utils/apis/services/api";
import urls from "../../../utils/apis/services/apiurls.json";
import download from "../../../assets/images/download.svg"
import LoadingOverlay from 'react-loading-overlay';


function Royalties() {

  const [royalties, setRoyalties] = useState([]);
  const [loader,setLoader]= useState(false)

  const getroyalties=async()=>{
    setLoader(true)
    let result = await getMethod(urls.getroyaltieslist,null)
    console.log(result.data)
    setRoyalties(result.data?result.data:[])
    setLoader(false)
  }

useEffect(()=>{
  getroyalties()
 },[])


return(

    <div className="royalties">
   
    <h2 >{translate("royalties")}</h2>
    <LoadingOverlay
  active={loader}
  spinner
  text={translate("loadertext")}
  >
    <table>

      <thead>
      <tr>
      <th style={{width:"15%"}}>{"Type"}</th>
      <th style={{width:"10%"}}>{"Amount"}</th>
      <th style={{width:"15%"}}>{"Name"}</th>
      <th style={{width:"15%"}}>{"Monthly Fee"}</th>
      <th style={{width:"15%"}}>{"Expiring Date"}</th>
      <th style={{width:"10%"}}>{"Events"}</th>
      <th style={{width:"10%"}}>{"State"}</th>
      <th style={{border:0}}>{"Invoice"}</th>
      </tr>
      </thead>
      {/* {item.isFixed?"$":"%"} */}
    <tbody>
   {
       royalties.map((item,index)=>{
           return <><tr>

           <td className="rowdata1">{item.royalty.action_type}</td>
           <td className="rowdata2">{item.royalty.amount} </td>
           <td className="rowdata3">{item.associated_partner.full_name}</td>
           <td className="rowdata4">{index} $</td>
           <td className="rowdata5">{moment(item.expirydate).format("DD/M/yyyy")}</td>
           <td className="rowdata6">{index}</td>
           <td className="rowdata7">{item.state}</td>
           <td className="rowdata8"><img src={download} alt=""/></td>
         </tr>
         
         </>
       })
   }
    </tbody>
   </table>
   </LoadingOverlay>

   </div>
)

}
export default Royalties ;