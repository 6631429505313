import React from 'react'
import {  Switch, Route } from "react-router-dom";
import PostTrackandEP from '../components/postTrackEp/posttrackep';
import Home from '../screen/panel/home/home';
import Partner from '../screen/panel/partner/partner';
import Profile from '../screen/panel/profile/profile';
import Royalties from '../screen/panel/royalties/royalties';
import Uploadcontent from '../screen/panel/uploadcontent/uploadcontent';
import UserList from '../screen/panel/userlist/userlist';
// import Signrequest from '../screen/panel/signrequest/signrequest';

export default function Panel(props){
const AdminPanel = [
  {
    path: "/home",
    exact: true,
    render: () => <Home />,
  },
  {
    path: "/userlist",
    exact: true,
    render: () => <UserList />,
  },
  {
    path: "/royalties",
    exact: true,
    render: () => <Royalties />,
  },
  {
    path: "/uploadcontent/course",
    exact: true,
    render: () => <Uploadcontent type={"course"} />,
  },
  {
    path: "/uploadcontent/charts",
    exact: true,
    render: () => <Uploadcontent type={"charts"} />,
  },
  {
    path: "/uploadcontent/samplelibrary",
    exact: true,
    render: () => <Uploadcontent type={"samplelibrary"} />,
  },
  {
    path: "/partner",
    exact: true,
    render: () => <Partner />,
  },
  {
    path: "/profile",
    exact: true,
    render: () => <Profile />,
  },
  {
    path: "/postatrack",
    exact: true,
    render: () => <PostTrackandEP />,
  }
];


  return(
      <Switch>
      {
      AdminPanel.map((route, index) =>(
        <Route key={index} path={route.path}  exact render={route.render} />
      ))
      }
    </Switch>
  )
}

