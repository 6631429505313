import React, { useEffect, useState } from "react";
import "./forgotpassword.scss";
import translate from "../../utils/locale/language";
import seedjlogo from "../../assets/images/seedjlogo.png"
import { useHistory } from 'react-router-dom';
import { postMethod } from "../../utils/apis/services/api";
import urls from "../../utils/apis/services/apiurls.json";
import { toast } from "react-toastify";

   function Forgotpassword(props) {
    const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
    const [state, setState] = useState({ email:"",errors:{email:""}});
    const [error, setError] = useState('');
    const [user, setUser] = useState('');
    
    const history = useHistory()
    const validateForm = errors => {
      let valid = true;
      console.log(errors)
      Object.values(errors).forEach(val => {
        if(val.length>0&&valid===true)
       {
         valid=true
       }
       else{
         valid=false
       }
      });
      return valid;
    };

      //  useEffect(()=>{
      //   verfiryToken();
      // },[])

    const handleChange = (e) => {
      const { name, value } = e.target;
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));


      let errors = state.errors;
      switch (name) {
        case 'email': 
        errors.email = 
          validEmailRegex.test(value)
            ? ''
            : 'Please enter a valid email';
        break;
        default:
          break;
      }
      setError('')

    };

    const handleSubmit = async(event) => {
        // let result =await postMethod(urls.loginurl,state,null)
        // console.log(result)
  
        // if(result.data&&!result.data.ErrorCode){
        //     setUserSession(encode(result.data.key));
        //     console.log(result.data)
        //     history.push("/dashboard")
        //     toast.success(translate("loginsuccess"))
        // }
        // else{
        //     //setError(translate("loginerror"))
        //     toast.error(translate("loginerror"))
        // }
    };

    return (

            <div className="forgotpassword-container">
            <div className="seedjlogo">
            <img src={seedjlogo} alt="seedj"/>
            </div>
            <div className="forgotpassword">
                <div>
                    <p className="hello">{translate("hello")}</p>
                    <p className="welcome">{translate("welcome")}</p>
                </div>
                <form>

                <h2 className="ForgotPassword">{translate("ForgotPassword")}</h2>

                <div className="form-group">
                  <label>Enter your registered e-mail to get link</label>
                  {state.errors.email.length > 0&&<span className='error'>{state.errors.email}</span>}
                  <input type="email" className="form-control" placeholder="Email-Address" name="email" onChange={handleChange}   />
                </div>

                {/* <div className="form-group">
                    <label>Password</label>
                    {state.errors.password.length>0&&<span className='error'>{state.errors.password}</span>}
                    <div style={{display:"flex"}}>
                    <input type="password" className="form-control" name="password" onChange={handleChange}  placeholder="Enter your password"   />
                    {state.errors.password.length>0&&<span><i class="fas fa-times"></i></span>}
                    {(state.errors.password.length===0&&state.password.length>0)&&<span><i class="far fa-check"></i></span>}
                    </div>
                </div>

                <div className="form-group">
                    <label>Confirm Password</label>
                    {state.errors.confirmpassword.length>0&&<span className='error'>{state.errors.confirmpassword}</span>}
                    <div style={{display:"flex"}}>
                    <input type="password" className="form-control" name="confirmpassword" onChange={handleChange}  placeholder="Confirm your password"  />
                    {state.confirmpassword.length>0&&(state.password===state.confirmpassword)&&<span><i class="far fa-check"></i></span>}
                    {state.confirmpassword.length>0&&(state.password!==state.confirmpassword)&&<span><i class="fas fa-times"></i></span>}
                    </div>
                </div> */}

                {error&&error.length >0&&<span className='apierror'>{error}</span>}

                <button type="submit" onClick={handleSubmit} className="btn btn-dark btn-lg btn-block resetpassword-btn">{translate("sendlink")}</button>
                
            </form>

            </div>
            </div>
        );
    }
    export default  Forgotpassword;