import React, { useEffect } from 'react';
import './App.scss';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Routes from './routes/Routes';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import firebase from "./firebase";

function App() {


  useEffect(()=>{
    // firebase.analytics()
  },[])

  return (
    <React.Fragment>
      <ToastContainer limit={1}/>
      <Routes />
    </React.Fragment>
  );
}

export default App;
