import React, { useEffect, useState } from 'react';
// import { getMethod,postMethodAuthenticated } from '../../../utils/apis/services/api';
// import { getToken } from '../../../utils/common';
import "./profile.scss";
import { useHistory } from 'react-router';

// import translate from "../../../utils/locale/language";

   function Profile() {

    const history=useHistory()
   

    return (
      <div className="profile">
        <div className="profileimgname">
          <img
            className="profileimg"
            src={"https://picsum.photos/200/300"}
            alt="profile"
          />
          <h2>{"Gabriel"}</h2>
          <p className="profiletype">{"Admin"}</p>
          {/* <p className="location">Milan - Italy</p> */}
          {/* <p className="bio">
           Born in the heart of the east Berlin,influenced
           by the well known techno-culture’s mecca. SLV (DE) stains the vibe 
           with melancholic harmonies driven by dark and cold grooves which make
           its sound unexampled.
           </p> */}
           <hr/>
        </div>
      </div>
    );

   }

   export default Profile;