import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import "./navbar.scss";
import translate, { getCurrentLocale, setLocale } from "../../utils/locale/language"
import seedjlogo from "../../assets/images/seedjlogo.png"
import { getToken, removeUserSession } from "../../utils/common";
import urls from "../../utils/apis/services/apiurls.json"
import { getMethod } from "../../utils/apis/services/api";


  function Navbar(props) {  

    const [profile,setProfile]=useState('')
    const history =useHistory()

    const userProfile=async()=>{
        let result =await getMethod(urls.userprofile,getToken())
        setProfile(result.data)
        window.sessionStorage.setItem("username",result.data.username)
      }

      useEffect(()=>{
        let lang=window.localStorage.getItem('lang')
        setLocale(lang)
        // userProfile();
      },[])

      const changeLanguage = (lan) => {
        setLocale(lan);
        alert(getCurrentLocale())
        window.localStorage.setItem("lang",lan);
        history.push(history.location.pathname);
    };
  
      return (      
        <div className="navbar">
        <div className='nav-title' >
        <img style={{width:"8vw"}} src={seedjlogo} alt=""/>
        </div>
        <ul className='nav-item' style={{width:props.type==="agency"&&"20%"}}>
        <span style={{display: "flex",flexDirection: "row",height:20}}>
               <p onClick={() => props.langchange? props.langchange("it"):changeLanguage("it")}
                  className={getCurrentLocale() === "it"? "lang_selected": "lang_notselected"}>
                  IT
                </p>
                <span className="lang_divider" />
                <p onClick={() => props.langchange? props.langchange("sp"):changeLanguage("sp")}
                  className={getCurrentLocale() === "sp"? "lang_selected": "lang_notselected"}>
                  ES
                </p>
                <span className="lang_divider" />
                <p onClick={() => props.langchange? props.langchange("en"):changeLanguage("en")}
                  className={getCurrentLocale() === "en"? "lang_selected": "lang_notselected"} >
                  EN
                </p>
              </span>

            <li>
                <NavLink exact to={()=>"/home"} className='nav-link ' activeClassName='link-active'>
                {translate("admin")} 
                </NavLink>
            </li>

            {/* <li>
                <NavLink exact onClick={()=>removeUserSession()} className='nav-link ' activeClassName='link-active'>
                {translate("logout")} 
                </NavLink>
            </li> */}

            <li onClick={()=>{removeUserSession();window.location.reload()}}><p style={{margin:0}}className='nav-link' >{translate('logout')}</p></li>

            
        </ul>
    </div>
      );
    }
  
 export default Navbar;