import React, { useState } from "react";
import "./login.scss";
import translate from "../../utils/locale/language";
import {setUserSession} from "../../utils/common";
import seedjlogo from "../../assets/images/seedjlogo.png"
import fb from "../../assets/images/facebook.svg"
import { useHistory } from 'react-router-dom';
import {  postMethod } from "../../utils/apis/services/api";
import urls from "../../utils/apis/services/apiurls.json";
import { toast } from "react-toastify";

   function Login() {

    const[error,setError]=useState('')
    const [state, setState] = useState({email:"",password:"",errors: {email: '',password: '',}});

    const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
    const passwordRegex = RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/); 

    const history = useHistory()
    const encode = (txt) => {
        let result = btoa(txt);
        return result;
      };
    const handleChange = (e) => {
      const { name, value } = e.target;
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));

    let errors = state.errors;

    switch (name) {
      case 'email': 
      errors.email = 
      value.length>0&&validEmailRegex.test(value)
          ? ''
          : 'Email is not valid!';
      break;
      case 'password': 
      errors.password = 
      value.length>0&&!passwordRegex.test(value)
          ? 'Password must be at least 8 characters long including only at least one uppercase letter, one lowercase letter, one number!'
          : '';
      break;
      default:
        break;
    }
    // setState((errors) => ({...errors, [name]: value}))
    setError('')
    };

    const handleSubmit = async(event) => {
      event.preventDefault();
      if(state.errors.email.length>0||state.errors.password.length>0)
      {
         toast.error(translate("entervaliddetails"))
      }
      else{
          let body={
          username:state.email.toLowerCase(),
          password:btoa(state.password)
          // password:state.password
        }
        let result =await postMethod(urls.loginurl,body,null)
        console.log(result)
        if(result.data&&!result.data.ErrorCode&&result.status===200&&result.data.role==="superuser"){
          setUserSession(encode(result.data.key));
           history.push("/home")
          toast.success(translate("loginsuccess"))
      }
      else{
          setError(result.data.Error)
          toast.error(result.data.Error)
        }
      }
    };


    return (

            <div className="login-container">
            <div className="seedjlogo">
            <img src={seedjlogo} alt="seedj"/>
            </div>
            <div className="login">
                <div>
                    <p className="hello">{translate("hello")}</p>
                    <p className="welcome">{translate("welcome")}</p>
                </div>
                <form onSubmit={handleSubmit}>

                <div className="form-group">
                    <label>{translate("username")}</label>
                    {state.errors.email.length > 0&&<span className='error'>{state.errors.email}</span>}
                    <input type="email" className="form-control" name="email" onChange={handleChange}  placeholder="Enter Email-Address" required />
                </div>

                <div style={{display:"flex"}}>
                <div className="form-group"  style={{width:"100%"}}>
                    <label>Password</label>
                    {state.errors.password.length > 0&&<span className='error'>{state.errors.password}</span>}
                    <input type="password" className="form-control" name="password" onChange={handleChange}  placeholder="Enter your password"  required />
                </div>
                <p className="forgot-password text-right">{translate("forgotpassword")}</p>
                </div>

                <div className="form-group">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="customCheck1" />
                        <label className="custom-control-label rememberme" htmlFor="customCheck1">{translate("rememberme")}</label>
                    </div>
                </div>

                {error&&error.length>0&&<span className='apierror'>{error}</span>}

                <button type="submit"  className="btn btn-dark btn-lg btn-block login-btn">{translate("login")}</button>
            </form>
            </div>
            </div>
        );
    }
    export default  Login;