import React  from "react";
import "./home.scss";
// import translate from "../../../utils/locale/language";
import { useHistory } from "react-router";


   function Home() {

    const history=useHistory()

   
    return (
      <div className="home">
      <p>Home</p>
      </div>
    );
   }

   export default Home ;