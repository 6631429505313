import React from 'react';
import { Switch, BrowserRouter as Router , useHistory} from 'react-router-dom';
import login from '../screen/login/login';
import PrivateRoute from '../utils/PrivateRoute';
import PublicRoute from '../utils/PublicRoute';
import Dashboard from '../screen/panel/dashboard/dashboard';
// import SetPassword from '../screen/setpassword/setpassword';
import Forgotpassword from '../screen/forgotpassword/forgotpassword';

export default function Routes() {
  const history = useHistory()
  
  return (
    <Router history={history}>
      <Switch >
        <PublicRoute exact path='/' component={login} />
        <PublicRoute exact path='/login' component={login} />
        <PrivateRoute  path="/dashboard" component={Dashboard} />
        <PrivateRoute path="/userlist" component={Dashboard} />
        <PrivateRoute path="/royalties" component={Dashboard} />
        <PrivateRoute path="/home" component={Dashboard} />
        <PrivateRoute path="/partner" component={Dashboard} />
        <PublicRoute path="/forgotpassword" component={Forgotpassword} />
        <PrivateRoute path="/profile" component={Dashboard} />
        <PublicRoute path="/postatrack" component={Dashboard} />
        <PrivateRoute path="/uploadcontent/course" component={Dashboard} />
        <PrivateRoute path="/uploadcontent/charts" component={Dashboard} />
        <PrivateRoute path="/uploadcontent/samplelibrary" component={Dashboard} />
        {/* <PublicRoute exact path='/resetpassword' component={SetPassword} /> */}
      </Switch>
    </Router>
  );
}