const sp= {
    home: "Home",
    library: "Corsi",
    exclusivesamples: "Samples esclusivi",
    monthlycharts: "Charts mensili",
    signrequest: "Sign Requests",
    challanges: "Sfide",
    contents: "Contest",
    community: "Community",
    hello: "Ciao!",
    welcome: "Benvenuto! / Benvenuta!",
    username: "Username",
    rememberme: "Ricordati di me",
    signin: "Registrati",
    forgotpassword: "Password dimenticata?",
    signinwith: "Oppure registrati con",
    donthaveaccount: "Non hai ancora un account?",
    signup: "Iscriviti",
    create: "Crea",
    account: "Account",
    addmusic: "Aggiungi musica",
    profile: "Profilo",
    setting: "Impostazioni",
    logout: "Disconnettiti",
    postatrack: "Pubblica una Traccia",
    postep: "Pubblica un EP",
    fresh: "fresca",
    music: " musica",
    oftoday: " di oggi",
    your: "le tue",
    lessons: " lezioni",
    tocontinue: " continua",
    hot: "Hot",
    samples: " Samples",
    exclusive: " esclusivo",
    download: "Scarica",
    latest: "le ultime",
    charts: " Charts",
    by: " per",
    newcontents: "nuovi contenuti",
    foryou: "per te",
    gotocharts: "vai ai Charts",
    seedjcommunity: "Seedj Community",
    library: "Libreria/ Catalogo/ Corsi",
    librarysubheading: "Video riguardo Teoria, Pratica e mindset dei DJs",
    tocontinue: "Continua",
    masterclass: "Masterclasses",
    producingtutorials: "Tutorials",
    musictheory: "Teoria della musica",
    backstage: '"Backstage",✌🏻',
    info: "Info",
    mymediakit: "Il mio Media Kit",
    mytracksep: "Le mie Tracce & EP",
    loginsuccess: "Accesso eseguito",
    loginerror: "Si prega di inserire le credenziali corrette",
    signupsuccess: "Registrazione eseguita con successo!",
    resetpassword: "Resetta la password",
    postatrack: "Pubblica una Traccia",
    title: "Titolo",
    addtracktitle: "Aggiungi il titolo della tua traccia qui... ",
    describetrack: "Describe your track to let people know what it is... ",
    genre: "Genere",
    genreplaceholder: "Aggiungi",
    previewimage: "Anteprima Immagine",
    selectimagetrack: "Seleziona un'immagine di copertina per la tua traccia",
    preview: "Anteprima",
    postonprofile: "Pubblica sul tuo profilo",
    commentontrack: "Aggiungi un commento per questa traccia",
    additionaldescription: "Scrivi una descrizione aggiuntiva",
    writedescription: "Scrivi qui la tua descrizione",
    monitoring: "Monitoring",
    monitoringsubheading: "Add labels to make it easier to manage and find your track",
    monitoringplaceholder: "Tag here some labels you want to like collaborate with",
    lable: "Lable",
    uploadzip: "carica il file .zip",
    uploadaudio: "carica il file Audio",
    drangdrophere: "trascina e rilascia qui",
    next: "Avanti",
    fileuploadsuccess: "File caricato con successo",
    trackuploadsuccess: "Traccia caricata con successo",
    epuploadsuccess: "EP caricato con successo",
    profilesigned:"Profile Signed",
    profilereviewed:"Profile Reviewed",
    similartoyours:"similar to yours",
    haveviewed:"have viewed",
    agency:"Agency",
    tracksigned:"Tracks Signed",
    trackreviewed:"Tracks Reviewed",
    similartoyours:"similar to yours",
    havelistened:"have listened",
    labels:"Labels",
    allcharts:"ALL CHARTS",
    newcharts:"New Charts",
    monthlycharts:"Monthly Charts",
    savedsamples:"Saved Samples",
    savedprofiles:"Saved Profiles",
    savedtracks:"Saved Tracks",
    addnewmedia:"Add New Media",
    photoorvideo:"photo or Video",
    addnewmusic:"Add New Muisc",
    trackoreps:"Track or EP's",
    sendlink:"send link",
    ForgotPassword:"Forgot Password",
    exclusivesamples:"Exclusive Samples",
    bestofmonth:"Best of the month",
    exclusiveloopsamples:"Exclusive loop & samples easy to get",
    trendingnow:"TRENDING NOW",
    mostviewed:"MOST VIEWED",
    signin:"Sign In",
    signup:"Sign Up",
    startyourclimb: "Empieza tu escalada",
    outonjuly30:"Lanzamiento el 30 de Julio",
    elctronicmusic:"Lo mejor de la industria de la música electrónica a tu disposición",
    allinone:"Todo en una suscripción. Cancela cuando quieras.",
    checkoutemailplaceholder:"Introduce tu correo electrónico para reservar",
    getexclusive:"Accede a exclusivos",
    contacts:"contactos",
    resources:"recursos" ,
    and:"y",
    contents:"contenido",
    everyday:"todos los días",
    justfewsteps:"Falta un poco y estarás dentro",
    createyourpassword:"Crea tu contraseña.",
    step:"Paso",
    of:"de",
    retry:"Inténtalo de nuevo",
    almostdone:"¡Casi has terminado!",
    checkyourmail:'"Haz clic en “enviar código de verificación" y revisa tu correo electrónico e introduce el código de verificación a continuación."',
    addverificationcode:"Introduce el código de verificación.",
    securepayment:"Pago seguro con PayPal.",
    cancelanytime: "Cancela tu suscripción cuando quieras sin coste adicional.",
    monthlyupdate:"Actualización mensual de funciones y contenido.",
    sendmecode:"Envia el código",
    resendsendmecode:"Envía el código de nuevo",
    preordersummary:"Comprobante de la reserva." ,
    wanttoreward:"Queremos agradecer a los primeros 500 DJ emergentes que reserven su acceso a SEEDJ. ",
    willgetpremium:"Obtendrás el plan Premium por el precio del Básico",
    changeorcancel:"Cambia o cancela cuando quieras sin costes adicionales",
    seatsavailable500:"500 plazas disponibles.",
    Onepersoneverymembership: "Solo es válida una persona por suscripción. Tu perfil será compartido con discográficas y agencias de booking.",
    recieveemail:"Recibirás un correo electrónico el 30 de julio de 2021 para acceder a la plataforma.",
    weeklyupdates:"Recibirás actualizaciones semanales sobre nuevos socios, contenido y funciones que encontrarás en SEEDJ ",
    unlimited:"Ilimitado",
    limitbasicplan:"Limitado en el plan Básico",
    masterclassandtutorials:"Master classes & Tutoriales",
    courses:"Cursos",
    courses5:"5 cursos)",
    trackuploads:"Subir pista",
    tracks3:"3 tracce)",
    sampleslibrary:"Biblioteca de samples",
    samples30:"30 samples )",
    dailycharts:"Biblioteca de charts",
    charts10:"10 charts )",
    labelrequest:"Solicitudes de las discográficas",
    request1:"1 solicitud )",
    Bookingrequest: "Solicitud a una agencia de booking",
    premium:"Premium*",
    yourlastchance:"¡Aprovecha esta oportunidad y únete al plan Premium por muy poco!",
    acceptcreditanddebit: "Aceptamos tarjeta de crédito y/o débito. Tu pago se realizará a través de Paypal",
    seatreserved10mins:"Tu plaza estará reservada durante 10 minutos",
    minutes:"minutos",
    gainaccess:"Podrás acceder a SEEDJ a partir del",
    date30july:"30 de julio 2021.",
    emailwithdate: "Recibirás un correo electrónico ese día con todas las instrucciones para iniciar tu sesión",
    Subscriptionrenewedmonthly:" Tu suscripción (€19) será renovada todos los meses automáticamente a partir del",
    date30sep:"30 de septiembre 2021.",
    onemonthfree:" (1 mes gratis).",
    cancelanytime:"Cancela cuando quieras.",
    receiveconfirmation:"Recibirás un email de confirmación",
    Afterpreorder:" Inmediatamente después de su reserva",
    preordernow:"Reserva ahora",
    paysecurelypaypal:"Paga de forma segura con PayPal",
    preorderconfirmed:"Tu reserva ha sido confirmada. Te hemos enviado un correo electrónico de confirmación. ¡Nos vemos en julio!",
    getaspoiler: "Echar un cotillear",
    done:"¡Hecho!",
    Trackuploadshint: "Sube tu pista y será escuchada por nuestras discográficas especializadas en tu micro género",
    Masterclassandtutorialshint: "Consume más de 150 horas de contenido con tus DJ y productores favoritos. Añadiremos 30 horas de contenido cada mes.",
    sampleslibraryhint:"Descarga +600 samples listos para usar en tus producciones. Añadiremos +200 muestras cada mes.",
    dailychartshint:"Accede a cientos de charts asociados a medios internacionales y DJ. ¡Actualizados a diario!",
    Labelrequesthint:" Recibe y acepta solicitudes de discográficas interesadas en tus pistas",
    bookingrequesthint:" Recibe y acepta solicitudes de parte de agencias de booking para unirse a su lista. ¡Mantén tu perfil actualizado! ;-) ",
    emailvalidsuccess:"Email registrado con éxito.",
    contactstip: "Conecta con más de 300 socios, incluidos agencias de booking, discográficas y DJ internacionales.",
    emailvaliderror:"Por favor, introduce un email válido.",
    allfieldmandatory:"Todos los campos son obligatorios.",
    validpassword:"Por favor, introduce una contraseña válida.",
    validotp:"Por favor, introduce una contraseña válida de un solo uso.",
    exclusive: "Esclusiva/Exclusivo",
    passwordtip:"Mín. 8 caracteres",
    passwordtip1:"1 Letra mayúscula ",
    passwordtip2:"1 Número",
    passwordtip3:"1 Letra minúscula ",
    valid:"Válida",
    notvalid:"No válida",
    month1free:"1 mes gratis",
    month1tip:"Activaremos tu suscripción el 30 de julio de 2021. Tu pago mensual comenzará el 30 de septiembre de 2021.",
    premiumplan:"El plan Premium",
    yourtimeup: "Se acabó el tiempo. Inténtalo de nuevo.",
    notmatching:"No coincide",
    Newmasterclasstip:"Accede a Master classes y tutoriales con tus DJ y productores favoritos, los actualizamos mensualmente.",
    resendhinttip: '"Si no recibes el código, revisa la bandeja de spam, o correo no deseado, o haz clic en "Reenviar el código" y comprueba tu correo electrónico.',
    resourcetip:"Recibe cientos de charts y samples exclusivos seleccionados acorde a tu género musical ¡Los actualizamos diariamente!",
    slotnotavailable:"No quedan plazas disponibles.",
    matching:"Coincide",
    entervaliddetails:"Por favor, introduce los datos correctos/válidos",
    loadertext:'Procesando...',	
    enterpassword:"Ingresa tu contraseña",
    confirmpassword:"Crea tu contraseña"
}

 export default sp;
  










 