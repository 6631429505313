import React, { useEffect, useState }  from "react";
import "./uploadcontent.scss";
import translate from "../../../utils/locale/language";
import UploadCourses from "./components/uploadcourses/uploadcourses";
import ContentlistTable from "./components/contentlist/contentlist";
import { useHistory } from "react-router-dom";
import { getMethod} from "../../../utils/apis/services/api";
import urls from "../../../utils/apis/services/apiurls.json";
// import { toast } from "react-toastify";
import LoadingOverlay from 'react-loading-overlay';
import Uploadsample from "./components/uploadsample/uploadsample";
import Uploadchart from "./components/uploadchart/uploadchart";

function Uploadcontent(props) {


 const [section,setSection]=useState(translate(props.type?props.type:"course"))
 const [sectiontype,setSectionType]=useState(2)
 const [sectionname,setSectioName]=useState(props.type)
 const [contentlist,setContentList]=useState([])
 const [editdata,setEditData]=useState(null)
 const [loader,setLoader]= useState(false)


const getContentList=async()=>{
  let contentlist=await getMethod(sectionname==="charts"?urls.uploadchart:sectionname==="course"?urls.uploadcontent:urls.samplecreate)
  setContentList(contentlist&&contentlist.data?contentlist.data:[])
}

useEffect(()=>{
  getContentList()
},[sectiontype])


const Edituser=async (id)=>{
  setLoader(true)
  let result=await getMethod(urls.uploadcontent+id+"/")
  setEditData(result.data)
  setSectionType(1)
  setLoader(false)
}

 const rendersection=(val)=>{
     
  switch (val) {
    case "course":
      return <UploadCourses setSection={()=>setSectionType(2)} editdata={editdata} setedit={()=>setEditData(null)} />;

    case "samplelibrary":
      return <Uploadsample setSection={()=>setSectionType(2)}  />;
    
    case "charts":
      return <Uploadchart setSection={()=>setSectionType(2)}  />;

    default:
      return;
  }

 }

 return(

     <div className="uploadcontent">
         <LoadingOverlay
           active={loader}
           spinner
           text={translate("loadertext")}
          >
       {
          section!==null&&<div className="selectedsection">
            {
              <div className="uploadcontentsection">

              <div className="pageheading" >
        
              <h2 > { sectiontype===1&&<p onClick={()=>setSectionType(2)}>{"<"}</p>} {section} list</h2>
        
              <div className="mainbuttons" >
               
               <button className="create" onClick={()=>setSectionType(1)}>{translate("create")}</button>
               <button className="bulk" onClick={()=>{setSectionType(2);setEditData(null)}}>{translate("bulk")}</button>
        
              </div>
        
              </div>  
        
              <div className="userlisttable">
        
               {
                 
                 sectiontype===2
                 ?
                 <ContentlistTable type={section} data={contentlist} contentlist={()=>getContentList()} editcourse={(id)=>Edituser(id)} setLoader={(val)=>setLoader(val)} setSection={()=>setSectionType(2)}/>
                 :
               rendersection(sectionname)
            }
        
              </div>
        
              </div>
            }
          </div>
       }
     </LoadingOverlay>
     </div>

 );

}

export default Uploadcontent