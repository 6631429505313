
import React, { useState }  from "react";
import "./createuserform.scss";
import translate from "../../../../utils/locale/language";
import Select from 'react-dropdown-select';


function CreateUserForm() {

 const [genre, setGenre] = useState("");

return(

    <div className="createuser">
   
    <h3 className="mainheading">{translate("create")}<b> {translate("user")}</b></h3>    

    <div className="createuserform">

    <div className="imageselector">
    </div>

    <div className="edituserfields" style={{width:"100%"}}>


    <div className="flexrows">

    <div className="textfieldedituser">
    <label>{translate("artist")}</label>
      <span className="divider"></span>
      <input placeholder="Enter artist name" defaultValue={"Daxj"}></input>
    </div>

    <div className="textfieldedituser">
      <label>{translate("email")}</label>
      <span className="divider"></span>
      <input placeholder="Enter E-mail" defaultValue={"Daxj@seedj.com"}></input>
    </div>

    </div>


    <div className="flexrows">

    <div className="textfieldedituser">
    <label>{translate("country")}</label>
      <span className="divider"></span>
      <input placeholder="Enter country" defaultValue={"Italy"}></input>
    </div>

    <div className="textfieldedituser">
      <label>{translate("coupon")}</label>
      <span className="divider"></span>
      <input placeholder="Enter coupon code" defaultValue={"#ERt#@"}></input>
    </div>

    </div>

    <div className="flexrows">

    <div className="textfieldedituser">
      <label>{translate("city")}</label>
      <span className="divider"></span>
      <input placeholder="Enter city" defaultValue={"Italy"}></input>
    </div>

    <div className="textfieldedituser">
      <label>{translate("facebook")}</label>
      <span className="divider"></span>
      <input placeholder="Insert facebook link" defaultValue={"@Daxj"}></input>
    </div>

    </div>

    <div className="flexrows">

    <div className="textfieldedituser">
      <label>{translate("age")}</label>
      <span className="divider"></span>
      <input placeholder="Enter age" defaultValue={18}></input>
    </div>

    <div className="genretags">
     <span className="genreimg">
     <p>{translate("maingenre")+": "}</p>
     </span>
      <div>
       <Select options={[]} labelField={"genre_name"} valueField={"slug"} onChange={(values) =>setGenre(values[0].slug)} className="ReactDropdownSelect" />
      </div>
   </div>

    </div>


    <div className="flexrows">

    <div className="textfieldedituser">
      <label>{translate("affiliatelink")}</label>
      <span className="divider"></span>
      <input placeholder="Add affliate link" defaultValue={"Italy"}></input>
    </div>

    <div className="textfieldedituser">
      <label>{translate("password")}</label>
      <span className="divider"></span>
      <input type="password" placeholder="Enter password" defaultValue={"Admin123"}></input>
    </div>

    </div>

    </div>

  </div>

  <p className="generateautomatically">{translate("generateautomatically")}</p>
  
  <button className="sendinvitation">{translate("sendinvitation")}</button>

  </div>
);

}

export default CreateUserForm;

