import React, { useState }  from "react";
import "./contentlist.scss";
// import translate from "../../utils/locale/language";
// import moment from "moment";
// import Select from 'react-dropdown-select';
import urls from "../../../../../utils/apis/services/apiurls.json";
import { deleteMethodAuthenticated, getMethod } from "../../../../../utils/apis/services/api";
import { toast } from "react-toastify";

function ContentlistTable({type,data,setLoader,editcourse,setSection,contentlist}) {

  const [edituser,setEdituser]=useState(null)
  const [hover, setHover] = useState(null);
  const hideCourse=async(id)=>{
    setLoader(true)
    let result=await getMethod(urls.uploadcontent+id+"/?action=hide")
    toast.success(result.data.message)
    setSection()
    setLoader(false)
  }

  const deleteCourse=async(id)=>{
    setLoader(true)
    let result=await getMethod(urls.uploadcontent+id+"/?action=delete")
    toast.success(result.data.message)
    contentlist()
    setLoader(false)
  }

  const deleteChart=async(id)=>{
    setLoader(true)
    let result=await deleteMethodAuthenticated(urls.uploadchart+id+"/")
    toast.success(result.data.message)
    contentlist()
    setLoader(false)
  }

  const deleteSample=async(id)=>{
    setLoader(true)
    let result=await deleteMethodAuthenticated(urls.samplecreate+id+"/")
    toast.success(result.data.message)
    contentlist()
    setLoader(false)
  }

return(
    <table className="contentlisttable">

      <thead>
      <tr>
      <th style={{width:"20%"}}>{"Select All"}</th>
      <th style={{width:"40%"}}>{type+" Name"}</th>
      <th style={{border:0}}>{"Author"}</th>
    </tr>
      </thead>


    <tbody>

   {
       type==="Courses"?
       data.map((item,index)=>{
           return <><tr>
           <td className="rowdata1" onClick={()=>editcourse(item.id)}>
            <img src={item.course_image} alt="" />
           </td>
           <td className="rowdata2" onClick={()=>editcourse(item.id)}>{item.name}</td>
           <td className="rowdata3" onClick={()=>editcourse(item.id)}>{item.tutor.full_name}</td>
           <td className="rowdata4"  onMouseEnter={()=>setHover(item.id)} onMouseLeave={()=>setHover(null)}><i class="far fa-ellipsis-h"></i>
           {hover===item.id&&<ul className="hovercontents">
             <li className="menu-link" onClick={()=>hideCourse(item.id)}>Hide</li>
             <li className="menu-link" onClick={()=>deleteCourse(item.id)}>Delete</li>
           </ul>}
           </td>
         </tr>
         </>
       })

       :
       type==="Courses"?

       data.map((item,index)=>{
        return <><tr>

        <td className="rowdata1" onClick={()=>editcourse(item.id)}>
         <img src={item.cover_image} alt="" />
        </td>
        <td className="rowdata2" onClick={()=>editcourse(item.id)}>{item.chart_name}</td>
        <td className="rowdata3" onClick={()=>editcourse(item.id)}>{item.author}</td>
        <td className="rowdata4"  onMouseEnter={()=>setHover(item.id)} onMouseLeave={()=>setHover(null)}><i class="far fa-ellipsis-h"></i>
        {hover===item.id&&<ul className="hovercontents">
          <li className="menu-link" onClick={()=>deleteChart(item.id)}>Delete</li>
        </ul>}
        </td>
      </tr>
      </>
    })
    :
    data.map((item,index)=>{
      return <><tr>

      <td className="rowdata1" onClick={()=>editcourse(item.id)}>
       <img src={item.cover_image} alt="" />
      </td>
      <td className="rowdata2" onClick={()=>editcourse(item.id)}>{item.name}</td>
      <td className="rowdata3" onClick={()=>editcourse(item.id)}>{item.author}</td>
      <td className="rowdata4"  onMouseEnter={()=>setHover(item.id)} onMouseLeave={()=>setHover(null)}><i class="far fa-ellipsis-h"></i>
      {hover===item.id&&<ul className="hovercontents">
        <li className="menu-link" onClick={()=>deleteSample(item.id)}>Delete</li>
      </ul>}
      </td>
    </tr>
    </>
  })
   }


    </tbody>


   </table>
)

}
export default ContentlistTable ;