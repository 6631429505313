const it= {
    home: "Home",
    library: "Corsi",
    exclusivesamples: "Samples esclusivi",
    monthlycharts: "Charts mensili",
    signrequest: "Sign Requests",
    challanges: "Sfide",
    contents: "Contest",
    community: "Community",
    hello: "Ciao!",
    welcome: "Benvenuto! / Benvenuta!",
    username: "Username",
    rememberme: "Ricordati di me",
    signin: "Registrati",
    forgotpassword: "Password dimenticata?",
    signinwith: "Oppure registrati con",
    donthaveaccount: "Non hai ancora un account?",
    signup: "Iscriviti",
    create: "Crea",
    account: "Account",
    addmusic: "Aggiungi musica",
    profile: "Profilo",
    setting: "Impostazioni",
    logout: "Disconnettiti",
    postatrack: "Pubblica una Traccia",
    postep: "Pubblica un EP",
    fresh: "fresca",
    music: " musica",
    oftoday: " di oggi",
    your: "le tue",
    lessons: " lezioni",
    tocontinue: " continua",
    hot: "Hot",
    samples: " Samples",
    exclusive: " esclusivo",
    download: "Scarica",
    latest: "le ultime",
    charts: " Charts",
    by: " per",
    newcontents: "nuovi contenuti",
    foryou: "per te",
    gotocharts: "vai ai Charts",
    seedjcommunity: "Seedj Community",
    library: "Libreria/ Catalogo/ Corsi",
    librarysubheading: "Video riguardo Teoria, Pratica e mindset dei DJs",
    tocontinue: "Continua",
    masterclass: "Masterclasses",
    producingtutorials: "Tutorials",
    musictheory: "Teoria della musica",
    backstage: '"Backstage",✌🏻',
    info: "Info",
    mymediakit: "Il mio Media Kit",
    mytracksep: "Le mie Tracce & EP",
    loginsuccess: "Accesso eseguito",
    loginerror: "Si prega di inserire le credenziali corrette",
    signupsuccess: "Registrazione eseguita con successo!",
    resetpassword: "Resetta la password",
    postatrack: "Pubblica una Traccia",
    title: "Titolo",
    addtracktitle: "Aggiungi il titolo della tua traccia qui... ",
    describetrack: "Describe your track to let people know what it is... ",
    genre: "Genere",
    genreplaceholder: "Aggiungi",
    previewimage: "Anteprima Immagine",
    selectimagetrack: "Seleziona un'immagine di copertina per la tua traccia",
    preview: "Anteprima",
    postonprofile: "Pubblica sul tuo profilo",
    commentontrack: "Aggiungi un commento per questa traccia",
    additionaldescription: "Scrivi una descrizione aggiuntiva",
    writedescription: "Scrivi qui la tua descrizione",
    monitoring: "Monitoring",
    monitoringsubheading: "Add labels to make it easier to manage and find your track",
    monitoringplaceholder: "Tag here some labels you want to like collaborate with",
    lable: "Lable",
    uploadzip: "carica il file .zip",
    uploadaudio: "carica il file Audio",
    drangdrophere: "trascina e rilascia qui",
    next: "Avanti",
    fileuploadsuccess: "File caricato con successo",
    trackuploadsuccess: "Traccia caricata con successo",
    epuploadsuccess: "EP caricato con successo",
    profilesigned:"Profile Signed",
    profilereviewed:"Profile Reviewed",
    similartoyours:"similar to yours",
    haveviewed:"have viewed",
    agency:"Agency",
    tracksigned:"Tracks Signed",
    trackreviewed:"Tracks Reviewed",
    similartoyours:"similar to yours",
    havelistened:"have listened",
    labels:"Labels",
    allcharts:"ALL CHARTS",
    newcharts:"New Charts",
    monthlycharts:"Monthly Charts",
    savedsamples:"Saved Samples",
    savedprofiles:"Saved Profiles",
    savedtracks:"Saved Tracks",
    addnewmedia:"Add New Media",
    photoorvideo:"photo or Video",
    addnewmusic:"Add New Muisc",
    trackoreps:"Track or EP's",
    sendlink:"send link",
    ForgotPassword:"Forgot Password",
    exclusivesamples:"Exclusive Samples",
    bestofmonth:"Best of the month",
    exclusiveloopsamples:"Exclusive loop & samples easy to get",
    trendingnow:"TRENDING NOW",
    mostviewed:"MOST VIEWED",
    signin:"Sign In",
    signup:"Sign Up",
    startyourclimb:"Inizia la tua scalata",
    outonjuly30:"Fuori il 30 Luglio",
    elctronicmusic:"Il meglio dell'industria di musica elettronica a tua disposizione",
    allinone:"Tutto in un unico abbonamento. Cancella quando vuoi.",
    checkoutemailplaceholder:"Inserisci la tua e-mail",
    getexclusive:"Ricevi esclusivi",
    contacts:"contatti",
    resources:"risorse",
    and:"e",
    contents:"contenuti",
    everyday:"ogni giorno",
    justfewsteps:"Solo pochi step e hai finito", 
    createyourpassword:"Crea la tua password",
    step:"Step",
    of:"di", 
    retry:"Torna indietro",
    almostdone:"Quasi fatto!",
    checkyourmail:'“Premi "Invia il codice", controlla la tua mail ed inserisci il codice di verifica qui sotto."',
    addverificationcode:"Inserisci codice di verifica",
    securepayment:"Pagamento sicuro con PayPal",
    cancelanytime: "Cancella quando vuoi senza costi aggiuntivi o nascosti.",
    monthlyupdate:"Aggiornamenti mensili",
    sendmecode:"Invia il codice",
    resendsendmecode:"Invia di nuovo il codice",
    preordersummary:"Riepilogo del pre-ordine." ,
    wanttoreward:"Vogliamo ringraziare i primi 500 DJ emergenti che effettueranno il pre-ordine a SEEDJ.",
    willgetpremium:"Otterrai il piano Premium allo stesso prezzo del Basic",
    changeorcancel:"Cambia o cancella quando vuoi senza costi aggiuntivi",
    seatsavailable500:"500 posti disponibili.",
    onepersoneverymembership:"Solo una persona per ogni abbonamnento. Il tuo profilo verrà visualizzato da Lables e Agenzie di booking.",
    recieveemail:"Riceverai un e-mail il 30 Luglio 2021 per accedere alla piattaforma.",
    weeklyupdates:"Riceverai aggiornamenti settimanali rigurardo nuovi partner, contenuti e funzionalità che troverai in SEEDJ",
    unlimited:"Illimitato",
    limitbasicplan:"(Limite per il piano Basic:",
    masterclassandtutorials:"Masterclasses & Tutorials",
    courses:"Corsi",
    courses5:"5 corsi)",
    trackuploads:"Tracks Uploads",
    tracks3:"3 tracce)",
    sampleslibrary:"Librerie Samples",
    samples30:"30 samples )",
    dailycharts:"Librerie Charts",
    charts10:"10 Charts )",
    labelrequest:"Richiese delle Labels",
    request1:"1 richiesta )",
    bookingrequest:"Richieste Agenzie",
    premium:"Premium*",
    yourlastchance:"Sarà l'unica opportunità per ottenere le funzionalità Premium a questo prezzo!",
    acceptcreditanddebit:"Accettiamo carte di credito o deibito. Il tuo pagamento verrà elaborato attraverso PayPal.",
    seatreserved10mins:"Il tuo posto verrà riservato per",
    minutes:"minuti",
    gainaccess:"Otterrai l'accesso a SEEDJ dal",
    date30july:" 30 Luglio 2021. ",
    emailwithdate: "Riceverai un e-mail in quel giorno con tutte le istruzioni per il login",
    subscriptionrenewedmonthly:"Il tuo abbonamento (€19) verrà rinnovato ogni mese a partire da",
    date30sep:" 30 Settembre 2021.",
    onemonthfree:" (1 mese gratis).",
    cancelanytime:"Cancella quando vuoi",
    receiveconfirmation:"Riceverai un e-mail di conferma",
    afterpreorder:"subito dopo il tuo pre-ordine",
    preordernow:"Pre-ordina adesso",
    paysecurelypaypal:"Paga in maniera sicura con PayPal",
    preorderconfirmed:"Il tuo pre-ordine è confermato. Ti abbiamo inviato un e-mail di conferma. Ci vediamo a Luglio!",
    getaspoiler: "Dai una sbirciata",
    done:"Fatto!",
    trackuploadshint:"Carica la tua traccia che verrà ascoltata dalle nostre Lables in base al tuo micro-genere",
    masterclassandtutorialshint:"Guarda 150 ore di contenuti con i tuoi DJ e Produttori preferiti. Aggiungiamo 30 ore di contenuti ogni mese.",
    sampleslibraryhint:"Scarica +600 Samples pronte da usare nelle tue produzioni. Aggiungiamo 240 Samples ogni mese.",
    dailychartshint:"Accedi a centinaia di Charts in partnership con Media e DJ internazionali. Aggiornate giornalmente.",
    labelrequesthint:"Ricevi e Accetta richieste da parte di Labels interesate alle tue tracce",
    bookingrequesthint:"Ricevi e Accetta richieste da parte di Agenzie di booking per entrare nel loro roster. Mantieni il tuo profilo aggiornato! ;-)",
    emailvalidsuccess:"Email registrata con successo.",
    contactstip:"Connetti con più di 300 partner incluse Agezie di booking, Etichette discografiche (Labels) e DJ internazionali.",
    emailvaliderror:"Per favore inserisci un e-mail valida.",
    allfieldmandatory:"Tutti i campi sono obbligatori",
    validpassword:"Per favore inserisci una password valida.",
    validotp:"Per favore inserisci una OTP valida.",
    exclusive:"Esclusiva",
    passwordtip:"Min. 8 caratteri",
    passwordtip1:"1 Lettera Maiuscola ",
    passwordtip2:"1 Numero",
    passwordtip3:"1 lettera minuscola ",
    valid:"Valida",
    notvalid:"Non valida",
    month1free:"1 mese gratis",
    month1tip:"Attiveremo il tuo abbonamento il 30 Luglio 2021. Il tuo pagamento mensile inizierà il 30 Settembre 2021.",
    premiumplan:"Il piano Premium",
    yourtimeup:"Tempo scaduto. Si prega di riprovare.",
    notmatching:"Non coincide",
    newmasterclasstip:"Guarda Mastercalss e Tutorials con i tuoi DJ e Produttori preferiti. Aggiornate mensilmente.",
    resendhinttip: 'Se non ricevi il codice, controlla il tuo spam o clicca "Invia di nuovo il codice" e controlla la tua e-mail.',
    resourcetip:"Ricevi centinaia di Charts e Samples esclusivi filtrati in base al tuo genere ed aggiornate giornalmente.",
    slotnotavailable:"Nessun posto rimasto al momento.",
    matching:"Coincide",
    entervaliddetails:"Per favore inserisci dettagli validi",
    loadertext:'Un secondo…',
    enterpassword:"Crea password",
    confirmpassword:"Ripeti password"
}
export default it;