import React, { useEffect, useState }  from "react";
import "./uploadchart.scss";
import translate from "../../../../../utils/locale/language";
import Select from 'react-dropdown-select';
import userpalceholder from '../../../../../assets/images/dashboard/userplaceholder.jpg'
import { getMethod, getMethodOpen, postMethodAuthenticated, putMethodAuthenticated } from "../../../../../utils/apis/services/api";
import urls from "../../../../../utils/apis/services/apiurls.json";
import { toast } from "react-toastify";
import LoadingOverlay from 'react-loading-overlay';
import moment from "moment";

function Uploadchart(props) {

    const [partnerlist, setPartnerList] = useState([]);
    const [coverimage,setCoverimg] = useState(null)
    const [cover_image,setCover_image] = useState(null)
    const [loader,setLoader]= useState(false)
    const [selectedtracklinks,setTracklinks]= useState([])
    const [state, setState] = useState({
      "chart_image":null,
      "chartname":"",
      "description": "",
      "genre": null,
      "publication_date":null,
      "expiration_date":null,
      "author" : null,
      "tracks":[],
      "track_count":0,
    });
    // const [tracksstate, setTracksState] = useState(
    //   {
    //     tracklink:"",
    //   });
    const [genrelist,setGenreList] = useState([]);
    const [author, setAuthor] = useState("");
    const [genre, setGenre] = useState("");
    const [is_trending,setisTrending]=useState(false)


      const getGenreList=async()=>{
        let genrelist=await getMethodOpen(urls.genrelist)
        setGenreList(genrelist&&genrelist.data?genrelist.data:[])
      }

      const getPartnersList=async()=>{
        let partnerslist=await getMethod(urls.getroles+"dj-partner")
        setPartnerList(partnerslist&&partnerslist.data?partnerslist.data:[])
      }

      const handleCoverImage=async(e)=>{
        setLoader(true)
        e.preventDefault();
        let formData = new FormData();
        formData.append("file", e.target.files[0]);
        setCover_image(e.target.files[0]);
        let result = await postMethodAuthenticated(urls.fileupload, formData);
        setCoverimg(result.data.id);
        setLoader(false)
      }

      const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
          
      };

      const handletrackcount = () => {
          let body={track_url:"",bpm:"",genre:"",artist_name:"",tracktitle:"",trackinfo:null}
          selectedtracklinks.push(body)
          setTracklinks([...selectedtracklinks])
      };

      const handleTrackChange = (e,trackindex) => {
        const { name, value } = e.target;
        selectedtracklinks.map((item,index) => 
        {
         if (trackindex === index){
           selectedtracklinks[index] = {...selectedtracklinks[index],[name]: value}
        }
       });
       };
 
       const deletetrack=(index)=>{
         selectedtracklinks.splice(index,1)
         setTracklinks([...selectedtracklinks])
       }

       const gettrackinfo=(trackindex)=>{
        selectedtracklinks.map((item,index) => 
        {
         if (trackindex === index){
          selectedtracklinks[index] = {...selectedtracklinks[index],trackinfo:{name:"testtrack"}}
          setTracklinks([...selectedtracklinks])
        }
         });

             }

      const handleSubmit = async(event) => {
        event.preventDefault();
        if(genre===""||author==="")
        {
           toast.error(translate("fillrequiredfields"))
        }
        else{
            setLoader(true)
            let body={
              "content_type": "chart",
              "cover_image":coverimage,
              "chart_name":state.chartname,
              "description": state.description,
              "genre": genre,
              "publication_date":state.publicationdate,
              "expiration_date":state.expirationdate,
              "author" : author,
              "tracks":selectedtracklinks.map(item=>item),
              "is_trending":is_trending
            }

          console.log(body)
      
          let result =await postMethodAuthenticated(urls.uploadchart,body)
          console.log(result.data)
          if(result.data&&!result.data.ErrorCode&&result.status===200){
            toast.success(result.data.message)
            props.setSection(1)
            setLoader(false)
          }
        else{
            setLoader(false)
            toast.error(result.data.Error)
             setLoader(false)
          }
        }
      };


      // const handleUpdate = async(event) => {
      //   event.preventDefault();
      //   if(genre===""||category==="")
      //   {
      //      toast.error(translate("fillrequiredfields"))
      //   }
      //   else{
      //       setLoader(true)
      //       let body={
      //         "id":props.editdata.id,
      //         "content_type": "course",
      //         "course_image":coverimage,
      //         "name":state.coursename,
      //         "affiliate_link": "seedj.app/"+state.coursename,
      //         "tutor": state.tutor,
      //         "description": state.description,
      //         "genre": genre,
      //         "language": state.language,
      //         "category": category,
      //         "subtitle":state.subtitle,
      //         "duration":state.duration,
      //         "publication_date":state.publicationdate,
      //         "expiration_date":state.expirationdate,
      //         "correlated_partners" : selectedlabels.map(item=>item.id),
      //         "classes":selectedtracklinks
      //       }
      //     console.log(body)
      //     let result =await putMethodAuthenticated(urls.uploadcontent+props.editdata.id+"/",body)
      //     console.log(result.data)
      //     if(result.data&&!result.data.ErrorCode&&result.status===200){
      //       toast.success(result.data.message)
      //       props.setedit()
      //       props.setSection()
      //       setLoader(false)
      //     }
      //   else{
      //       setLoader(false)
      //       toast.error(result.data.Error)
      //        setLoader(false)
      //     }
      //   }
      // };


      useEffect(()=>{
        getGenreList()
        getPartnersList()
      },[])

    return(
      <LoadingOverlay
      active={loader}
      spinner
      text={translate("loadertext")}
      >

        <div className="uploadcharts">

            <h2 className="heading">{"Charts"}</h2>
            <form onSubmit={handleSubmit}>

            <div className="coverimageselector">
               <label className="custom-file-upload">
               <input type="file" onChange={handleCoverImage} accept="image"></input>
               <img  class={cover_image ?"uploadedimage":props.editdata?"uploadedimage":"uploadsymbol"} src={cover_image ? URL.createObjectURL(cover_image) :props.editdata?props.editdata.course_image:"uploadicon"} alt=""/>
               {(cover_image||props.editdata)?'':<p>Upload cover image</p>}
              </label>
             </div>


            <div className="flexrows">
            <div className="genretags">
            <span className="genreimg">
            <p>{translate("author")+": "}</p>
            </span>
            <div>
            {/* setCategory(values[0].slug) */}
             <Select options={partnerlist} labelField={"full_name"} valueField={"id"} onChange={(values) =>setAuthor(values[0].id)} className="ReactDropdownSelect" />
            </div>
            </div>

            <div className="genretags">
            <span className="genreimg">
            <p>{translate("genre")+": "}</p>
            </span>
            <div>
            {/* setCategory(values[0].slug) */}
             <Select options={genrelist} labelField={"genre_name"} valueField={"slug"} onChange={(values) =>setGenre(values[0].slug)} className="ReactDropdownSelect" />
            </div>
            </div>

            {/* <div className="textfieldedituser">
             <label>{"Number of tracks"}</label>
             <span className="divider"></span>
             <input type="text" name="track_count" value={state.track_count} onChange={handletrackcount} required ></input>
            </div> */}

            <div className="custom-control custom-checkbox" style={{width:"50%"}}>
              <input type="checkbox" className="custom-control-input" id="customCheck1" defaultChecked={state.is_trending} onChange={(e)=>setisTrending(e.currentTarget.checked)} />
              <label className="custom-control-label rememberme" htmlFor="customCheck1">{"Is Trending"}</label>
            </div> 

            </div>

            <div className="textfieldedituser">
             <label>{"Chart name"}</label>
             <span className="divider"></span>
             <input type="text" name="chartname" value={state.chartname} onChange={handleChange} required ></input>
            </div>



            <div className="textareaedituser">
             <label>{translate("description")}</label>
             <span className="divider"></span>
             <textarea placeholder="Describe your chart" value={state.description} name="description" onChange={handleChange} required ></textarea>
            </div>

            <div className="flexrows">

            <div className="textfieldedituser">
             <label>{translate("publicationdate")}</label>
             <span className="divider"></span>
             <input type="date" name="publicationdate" value={state.publicationdate} onChange={handleChange} min={moment(new Date()).format("yyyy-MM-D")} required ></input>
            </div>

            <div className="textfieldedituser">
             <label>{translate("expirationdate")}</label>
             <span className="divider"></span>
             <input type="date" name="expirationdate" value={state.expirationdate} onChange={handleChange} min={moment(state.publicationdate).format("yyyy-MM-D")} required ></input>
            </div>
            </div>


            
        {
         selectedtracklinks.map((item,index)=>{
          return <div className="sampletrackscontainer">    
            <div className="sampletrackstitle">
             <p className="dashline">{"---"+(index+1)}</p>
             {/* <p style={{display:"flex"}}>{translate("filename")}:<p className="detail">{"sample1 1abc1xyz.mp3"}</p></p> */}
             {/* <p style={{marginLeft:"auto",display:"flex"}}>{translate("duration")}:<p className="detail">{"3:00"}</p></p> */}
           </div>
            <div className="flexrows">
            <div className="textfieldedituser">
             <label>{"Track link"}</label>
             <span className="divider"></span>
             <input type="text" name="track_url" placeholder="Paste Beatport track link here..." onChange={(e)=>handleTrackChange(e,index)} required ></input>
             {/* <label className="gettrack" onClick={()=>gettrackinfo(index)}  >{"Get Trackinfo"}</label> */}
            </div>
            <i class="fas fa-trash-alt" style={{float:"right"}} onClick={()=>deletetrack(index)}></i>
            </div>

            <div className="flexrows">
            <div className="textfieldedituser">
             <label>{"Track title"}</label>
             <span className="divider"></span>
             <input type="text" name="tracktitle" placeholder="Enter track title here..." onChange={(e)=>handleTrackChange(e,index)} required ></input>
            </div>

            <div className="textfieldedituser">
             <label>{"Artist"}</label>
             <span className="divider"></span>
             <input type="text" name="artist_name" placeholder="Enter artist name here..." onChange={(e)=>handleTrackChange(e,index)} required ></input>
            </div>

            </div>

            <div className="flexrows">
            <div className="textfieldedituser">
             <label>{"BPM"}</label>
             <span className="divider"></span>
             <input type="text" name="bpm" placeholder="BPM" onChange={(e)=>handleTrackChange(e,index)} required ></input>
            </div>

            <div className="textfieldedituser">
             <label>{"Genre"}</label>
             <span className="divider"></span>
             <input type="text" name="genre" placeholder="Enter genre" onChange={(e)=>handleTrackChange(e,index)} required ></input>
            </div>

            </div>

            {
            item.trackinfo&&<table>
            <thead>
            <tr>
            <th style={{width:"30%"}}>{"Name"}</th>
            <th style={{width:"35%"}}>{"Description"}</th>
            <th style={{width:"35%"}}>{"Publicate Date"}</th>
            </tr>
            </thead>
            <tbody>
            <tr>
             <td className="rowdata1">{item.trackinfo.name}</td>
             <td className="rowdata2">{item.trackinfo.name}</td>
             <td className="rowdata3">{item.trackinfo.name}</td>
            </tr>
            </tbody>
            </table>
            }
         </div>
         })
         }
        <label className="addtrackbutton" onClick={()=>handletrackcount()} style={{width:'10%'}} >{"Add track"}</label>
        
        <button className="uploadbutton" type="submit" >{props.editdata?translate("update"):translate("upload")}</button>

         </form>
        </div>
        </LoadingOverlay>
    )

}

export default Uploadchart;