import React, { useEffect, useState }  from "react";
import "./uploadcourses.scss";
import translate from "../../../../../utils/locale/language";
import Select from 'react-dropdown-select';
import userpalceholder from '../../../../../assets/images/dashboard/userplaceholder.jpg'
import { getMethod, getMethodOpen, postMethodAuthenticated, putMethodAuthenticated } from "../../../../../utils/apis/services/api";
import urls from "../../../../../utils/apis/services/apiurls.json";
import { toast } from "react-toastify";
import LoadingOverlay from 'react-loading-overlay';
import moment from "moment";

function UploadCourses(props) {
    console.log(props.editdata)
    const [showlabel,setShowLabel]=useState(false)
    const[searchterm,setsearch]=useState('')
    const[selectedlabels,setSelectedlabels]=useState(props.editdata?props.editdata.correlated_partners:[])
    // const [partnerlist, setPartnerList] = useState([]);
    const [tutorlist, setTutorsList] = useState([]);
    const [profileimage,setProfileimg] = useState(null)
    const [profile_image,setProfile_image] = useState(null)
    const [coverimage,setCoverimg] = useState(null)
    const [cover_image,setCover_image] = useState(null)
    const [loader,setLoader]= useState(false)
    const [courseclasses,setCourseClasses]= useState(false)
    const [state, setState] = useState({
      coursename:props.editdata?props.editdata.name:"",
      tutor:props.editdata?props.editdata.tutor:"",
      description:props.editdata?props.editdata.description:"",
      language:props.editdata?props.editdata.language:"",
      subtitle:props.editdata?props.editdata.subtitle:"",
      duration:props.editdata?props.editdata.duration:"",
      publicationdate:props.editdata?props.editdata.publication_date:null,
      expirationdate:props.editdata?props.editdata.expiration_date:null
    });
    const [genrelist,setGenreList] = useState([]);
    const [category, setCategory] = useState([]);
    const [categorylist, setCategoryList] = useState([]);
    const [genre, setGenre] = useState("");
    const [classstate, setClassState] = useState(
      {
        classname:"",
        vimeolink:"",
        description:"",
        classimage:null,
        publicationdate:null,
        duration:"",
        order_number:'',
        errors: {
          classname: '',
          vimeolink: '',
          publicationdate:null,
          description:"",
          duration:""
        }
      });
    const [selectedclasses,setSelectedClasses]=useState(props.editdata?props.editdata.classes:[])
    const [editclass,setEditClass]=useState(null)

    let labelSearchResult = tutorlist&&tutorlist.filter(label => {
        return label.full_name&&label.full_name.toLowerCase().includes(searchterm)
        })
    
      const addLabel=(item,index)=>{
          selectedlabels.push(item)
          tutorlist.splice(index,1)
          setShowLabel(!showlabel)
      }
    
      const labelstags=(item)=>{
        return <>
        <img alt="" className="customlabelimage" src={item.profile_image?item.profile_image:userpalceholder}></img>
        </>
      }

      const getGenreList=async()=>{
        let genrelist=await getMethodOpen(urls.genrelist)
        setGenreList(genrelist&&genrelist.data?genrelist.data:[])
      }

      const getCatogeoryList=async()=>{
        let catogeorylist=await getMethodOpen(urls.getcoursecategory)
        setCategoryList(catogeorylist&&catogeorylist.data?catogeorylist.data:[])
      }

      // const getPartnersList=async()=>{
      //   let partnerslist=await getMethod(urls.getroles+"dj-partner")
      //   setPartnerList(partnerslist&&partnerslist.data?partnerslist.data:[])
      // }

      const getTutorsList=async()=>{
        let tutorslist=await getMethod(urls.getroles+"tutors")
        setTutorsList(tutorslist&&tutorslist.data?tutorslist.data:[])
      }

      const languages=[
        {
          name:"Spanish",
          slug:"es"
        },
        {
          name:"English",
          slug:"en"
        },   
        {
          name:"Italian",
          slug:"it"
        }
      ]

      const handleImage=async(e)=>{
        e.preventDefault();
        setLoader(true)
        let formData = new FormData();
        formData.append("file", e.target.files[0]);
        setProfile_image(e.target.files[0]);
        let result = await postMethodAuthenticated(urls.fileupload, formData);
        setProfileimg(result.data.id);
        setLoader(false)
      }

      const handleCoverImage=async(e)=>{
        setLoader(true)
        e.preventDefault();
        let formData = new FormData();
        formData.append("file", e.target.files[0]);
        setCover_image(e.target.files[0]);
        let result = await postMethodAuthenticated(urls.fileupload, formData);
        setCoverimg(result.data.id);
        setLoader(false)
      }

      const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
          
      };

      const handleClassChange = (e) => {
        const { name, value } = e.target;
        setClassState((prevState) => ({
          ...prevState,
          [name]: value,
        }));

        let errors = classstate.errors;

        switch (name) {
          case 'vimeolink': 
          errors.vimeolink = 
          value.length<10
              ? 'Link is not valid!'
              : '';
          break;

          case 'classname': 
          errors.classname = 
          value.length<1
          ? 'Classname is not valid!'
          : '';
          break;

          case 'publicationdate': 
          errors.publicationdate = 
          value!==null
          ? 'Date is not valid!'
          : '';
          break;

          case 'description': 
          errors.description = 
          value.length<1
              ? 'Please enter description.'
              : '';
          break;

          case 'duration': 
          errors.duration = 
          value.length<3
              ? 'Please enter duration.'
              : '';
          break;
          default:
            break;
        }
      };


      const handleClassEdit = (e) => {
      const { name, value } = e.target;
       selectedclasses.map((item,index) => 
       {
        if (editclass === index){
          selectedclasses[index] = {...selectedclasses[index],
            [name]: value
        }
        selectedclasses[index] = {...selectedclasses[index],
          resource: profileimage
      }
      }
      });
      console.log(selectedclasses)
      };

      const deleteclass=()=>{
        selectedclasses.splice(editclass,1)
        setEditClass(null)
      }

      const handleSubmit = async(event) => {
        event.preventDefault();
        if(genre===""||category==="")
        {
           toast.error(translate("fillrequiredfields"))
        }
        else{
            setLoader(true)
            let body={
              "content_type": "course",
              "course_image":coverimage,
              "name":state.coursename,
              "affiliate_link": "seedj.app/"+state.coursename,
              "tutor": state.tutor,
              "description": state.description,
              "genre": genre,
              "language": state.language,
              "category": category,
              "subtitle":state.subtitle,
              "duration":state.duration,
              "publication_date":state.publicationdate,
              "expiration_date":state.expirationdate,
              "correlated_partners" : selectedlabels.map(item=>item.id),
              "classes":selectedclasses
            }
          console.log(body)
      
          let result =await postMethodAuthenticated(urls.uploadcontent,body)
          console.log(result.data)
          if(result.data&&!result.data.ErrorCode&&result.status===200){
            toast.success(result.data.message)
            props.setSection()
            setLoader(false)
          }
        else{
            setLoader(false)
            toast.error(result.data.Error)
             setLoader(false)
          }
        }
      };


      const handleUpdate = async(event) => {
        event.preventDefault();
        if(genre===""||category==="")
        {
           toast.error(translate("fillrequiredfields"))
        }
        else{
            setLoader(true)
            let body={
              "id":props.editdata.id,
              "content_type": "course",
              "name":state.coursename,
              "affiliate_link": "seedj.app/"+state.coursename,
              "tutor": state.tutor,
              "description": state.description,
              "genre": genre,
              "language": state.language,
              "category": category,
              "subtitle":state.subtitle,
              "duration":state.duration,
              "publication_date":state.publicationdate,
              "expiration_date":state.expirationdate,
              "correlated_partners" : selectedlabels.map(item=>item.id),
              "classes":selectedclasses
            }
            if(coverimage!==null)
            {
              Object.assign(body,{"course_image":coverimage});
            }
          console.log(body)
          let result =await putMethodAuthenticated(urls.uploadcontent+props.editdata.id+"/",body)
          console.log(result.data)
          if(result.data&&!result.data.ErrorCode&&result.status===200){
            toast.success(result.data.message)
            props.setedit()
            props.setSection()
            setLoader(false)
          }
        else{
            setLoader(false)
            toast.error(result.data.Error)
             setLoader(false)
          }
        }
      };


      const handleCategory =(values)=>{
        console.log(values)
        setCategory(values.map((item)=>{return item.slug}));
      }

      const addMoreClass=(e,val)=>{
        // if(classstate.errors.description.length>0||classstate.errors.classname.length>0||classstate.errors.vimeolink.length>0||classstate.errors.publicationdate.length>0)
        // {
        //   toast.error("please fill valid data")
        // }
        // else{
          e.preventDefault();
          let body={
            name:classstate.classname,
            vimeolink:classstate.vimeolink,
            description:classstate.description,
            classimage:classstate.classimage,
            publication_date:classstate.publicationdate,
            resource:profileimage,
            duration: classstate.duration,
            order_number:classstate.order_number
          }
          setSelectedClasses([...selectedclasses,body])
          setCourseClasses(val==="next"?true:false)
          setProfile_image(null)
          setProfileimg(null)
        // }
      }

      useEffect(()=>{
        getGenreList()
        getCatogeoryList()
        // getPartnersList()
        getTutorsList()
      },[])

    return(
      <LoadingOverlay
      active={loader}
      spinner
      text={translate("loadertext")}
      >

        <div className="uploadcourses">

            <h2 className="heading">{translate("course")}</h2>
            <form onSubmit={props.editdata?handleUpdate:handleSubmit}>

            <div className="coverimageselector">
               <label className="custom-file-upload">
               <input type="file" onChange={handleCoverImage} accept="image"></input>
               <img  class={cover_image ?"uploadedimage":props.editdata?"uploadedimage":"uploadsymbol"} src={cover_image ? URL.createObjectURL(cover_image) :props.editdata?props.editdata.course_image:"uploadicon"} alt=""/>
               {(cover_image||props.editdata)?'':<p>Select Course Image</p>}
              </label>
             </div>

            <div className="textfieldedituser">
             <label>{translate("name")}</label>
             <span className="divider"></span>
             <input placeholder="Write the name of course here..." value={state.coursename} name="coursename" onChange={handleChange} required ></input>
            </div>

            <div className="textfieldedituser">
             <label>{translate("tutor")}</label>
             <span className="divider"></span>
             {/* <input placeholder="Enter tutor name here..." value={state.tutor} name="tutor" onChange={handleChange} required ></input> */}
             <div className="dropdownselector">
             <Select options={tutorlist} labelField={"full_name"} valueField={"id"} onChange={(values) =>setState((prevState) => ({...prevState,tutor:values[0].id}))} className="ReactDropdownSelect" />
             </div>
            </div>

            <div className="textareaedituser">
             <label>{translate("description")}</label>
             <span className="divider"></span>
             <textarea placeholder="Describe your video to let people know what it is…" value={state.description} name="description" onChange={handleChange} required ></textarea>
            </div>

            <div className="flexrows">

            <div className="textfieldedituser">
             <label>{translate("language")}</label>
             <span className="divider"></span>
             {/* <input type="text" name="language" onChange={handleChange} ></input> */}
             <div className="dropdownselector">
             <Select options={languages} labelField={"name"} valueField={"slug"} onChange={(values) =>setState((prevState) => ({...prevState,language:values[0].slug}))} className="ReactDropdownSelect" />
             </div>
            </div>

            <div className="textfieldedituser">
             <label>{translate("subtitle")}</label>
             <span className="divider"></span>
             <input type="text" name="subtitle" value={state.subtitle} onChange={handleChange} required ></input>
            </div>

            </div>


            <div className="flexrows">

            <div className="textfieldedituser">
             <label>{translate("duration")}</label>
             <span className="divider"></span>
             <input type="text" name="duration" value={state.duration} onChange={handleChange} required ></input>
            </div>

            <div className="genretags">
            <span className="genreimg">
            <p>{translate("genre")+": "}</p>
            </span>
            <div>
            {/* setCategory(values[0].slug) */}
             <Select options={genrelist} labelField={"genre_name"} valueField={"name"} onChange={(values) =>setGenre(values[0].slug)} className="ReactDropdownSelect" />
            </div>
            </div>

            </div>

            <div className="flexrows">

            <div className="textfieldedituser">
             <label>{translate("publicationdate")}</label>
             <span className="divider"></span>
             <input type="date" name="publicationdate" value={state.publicationdate} onChange={handleChange} min={props.editdata&&props.editdata.publication_date?"":moment(new Date()).format("yyyy-MM-D")} required ></input>
            </div>

            <div className="textfieldedituser">
             <label>{translate("expirationdate")}</label>
             <span className="divider"></span>
             <input type="date" name="expirationdate" value={state.expirationdate} onChange={handleChange} min={moment(state.publicationdate).format("yyyy-MM-D")} required ></input>
            </div>
            </div>


          <div className="flexrows">

            <div className="monitoringfield">
            <span className="genreimg">
            <p>{translate("corelatedpartner")+": "}</p>
            </span>
             {
               selectedlabels.map((item)=>{
                return  labelstags(item)
               })
              }
            {selectedlabels.length<6&&<span className="customlabels" onClick={()=>setShowLabel(!showlabel)}>+</span>}
            <div  class={showlabel?"dropdown-content show":"dropdown-content"} >
            <input type="text" placeholder="Search..."  onChange={(e)=>setsearch(e.target.value)} />
            {labelSearchResult.map((item,index)=>{return <p onClick={()=>addLabel(item,index)}>{item.full_name}</p>})}
            </div>
            </div>

            <div className="genretags">
            <span className="genreimg">
            <p>{translate("category")+" "}</p>
            </span>
            {/* <div>
             <Select options={categorylist} labelField={"name"} valueField={"slug"} onChange={(values) =>setCategory(values[0].slug)} className="ReactDropdownSelect" />
            </div> */}

            <div className="dropdownselector">
                <Select multi options={categorylist} labelField={"name"} valueField={"id"} onChange={(values)=>handleCategory(values)} className="ReactDropdownSelect" />
            </div>

            </div>

            </div>

            <div className="uploadcourseheader">
             <p onClick={()=>{setCourseClasses(!courseclasses)}}>{translate("createnewclass")}</p>
            </div>

            <table>

            <thead>
            <tr>
            <th style={{width:"30%"}}>{"Class Name"}</th>
            <th style={{width:"35%"}}>{"Description"}</th>
            <th style={{width:"35%"}}>{"Publicate Date"}</th>
            </tr>
            </thead>

     <tbody>
        {
         selectedclasses.map((item,index)=>{
         return <><tr onClick={()=>setEditClass(index)}>
         <td className="rowdata1">{item.name}</td>
         <td className="rowdata2">{item.description}</td>
         <td className="rowdata3">{item.publication_date}</td>
         </tr>
         {
            (editclass===index)&&<div className="newcourseform" style={{width:"235%"}}>
             <form>

             <div className="flexrows">
             <p className="nameofclass">{index+1} {"Class order"}:</p>
             <input className="courseforminput" placeholder="Enter class order here...  " name="order_number" required onChange={handleClassEdit}></input>
             <p className="closeiconcourse" onClick={()=>setEditClass(null)}>X</p>
            </div>

             <div className="flexrows">
             <p className="nameofclass">{translate("nameofclass")}:</p>
             <input className="courseforminput" placeholder="Enter class name here...  " name="name" defaultValue={item.name} required onChange={handleClassEdit}></input>
             {/* <p className="closeiconcourse" onClick={()=>setEditClass(null)}>X</p> */}
            </div>

            <div className="flexrows">

            <div className="textfieldedituser">
             <label>{translate("Vimeolink")}</label>
             <span className="divider"></span>
             <input defaultValue={item.vimeolink} type="text" placeholder="Describe your video to let people know what it is…" style={{width:"60%"}} required name="vimeolink" onChange={handleClassEdit}></input>
            </div>

            <div className="timedurationfield">
            <div className="durationtime">
                <label>{translate("duration")}:</label>
                <input defaultValue={item.duration} className="textfield" type="text" required name="duration" onChange={handleClassEdit}></input>
               </div>
            </div>

            </div>

            <div className="textareaedituser">
             <label>{translate("description")}</label>
             <span className="divider"></span>
             <textarea defaultValue={item.description} placeholder="Describe your video to let people know what it is…" name="description" onChange={handleClassEdit} required ></textarea>
            </div>

            <div className="textfieldeditcoursedate">
             <label>{translate("publicationdate")}</label>
             <span className="divider"></span>
             <input defaultValue={item.publication_date} type="date" name="publicationdate"  onChange={handleClassEdit} required ></input>
            </div>

             <div className="flexrows" style={{margin:"2vw 0"}}>

             <p className="resource">{translate("resource")}:</p>

             <div className="resourceimageselector">
               <label className="custom-file-upload">
               <input type="file" onChange={handleImage} accept="image"></input>
               {/* <img src={uploadicon} class="uploadsymbol" /> */}
              <img  class={profile_image ?"uploadedimage":"uploadsymbol"} src={profile_image ? URL.createObjectURL(profile_image) :item.resource} alt=""/>
              </label>
             </div>

            </div>

            <div style={{display:"flex"}}>
            <button className="addnextclass" onClick={(e)=>{e.preventDefault();setEditClass(null)}} >{translate("updateclass")}</button>
            <button className="addnextclass"   onClick={(e)=>{e.preventDefault();deleteclass()}} >Delete</button>
            </div>
            </form>
           </div>
         }
          </>
           })
          }
          </tbody>
          </table>

           {
            courseclasses&&<div className="newcourseform">
              {/* <form> */}

            <div className="flexrows">
             <p className="nameofclass">{selectedclasses.length+1} {"Class order"}:</p>
             <input className="courseforminput" placeholder="Enter class order here...  " name="order_number" required onChange={handleClassChange}></input>
             <p className="closeiconcourse" onClick={()=>setCourseClasses(!courseclasses)}>X</p>
            </div>

             <div className="flexrows">
             <p className="nameofclass">{translate("nameofclass")}:</p>
             <input className="courseforminput" placeholder="Enter class name here...  " name="classname" required onChange={handleClassChange}></input>
             {/* <p className="closeiconcourse" onClick={()=>setCourseClasses(!courseclasses)}>X</p> */}
            </div>

            <div className="flexrows">

            <div className="textfieldedituser">
             <label>{translate("Vimeolink")}</label>
             <span className="divider"></span>
             <input type="text" placeholder="Describe your video to let people know what it is…" style={{width:"60%"}} required name="vimeolink" onChange={handleClassChange}></input>
            </div>

            <div className="timedurationfield">
               <div className="durationtime">
                <label>{translate("duration")}:</label>
                <input className="textfield" type="text" required name="duration" onChange={handleClassChange}></input>
               </div>
            </div>

            </div>

            <div className="textareaedituser">
             <label>{translate("description")}</label>
             <span className="divider"></span>
             <textarea placeholder="Describe your video to let people know what it is…" name="description" onChange={handleClassChange} required ></textarea>
            </div>

            <div className="textfieldeditcoursedate">
             <label>{translate("publicationdate")}</label>
             <span className="divider"></span>
             <input type="date" min={new Date()} name="publicationdate" min={moment(new Date()).format("yyyy-MM-D")} onChange={handleClassChange} required ></input>
            </div>
             <div className="flexrows" style={{margin:"2vw 0"}}>

             <p className="resource">{translate("resource")}:</p>

             <div className="resourceimageselector">
               <label className="custom-file-upload">
               <input type="file" onChange={handleImage} accept="image"></input>
               {/* <img src={uploadicon} class="uploadsymbol" /> */}
              <img class={profile_image ?"uploadedimage":"uploadsymbol"} src={profile_image ? URL.createObjectURL(profile_image) :"uploadicon"} alt=""/>
              </label>
             </div>
            </div>

            <div style={{display:"flex"}}>
            <button className="addnextclass"  onClick={(e)=>addMoreClass(e,"single")} >{translate("addclass")}  +</button>
            <button className="addnextclass"   onClick={(e)=>addMoreClass(e,"next")} >{translate("addnextclass")}  +</button>
            </div>
            {/* </form> */}
           </div>
           }

          <button className="uploadbutton" type="submit" >{props.editdata?translate("update"):translate("upload")}</button>
         </form>
        </div>
        </LoadingOverlay>
    )

}

export default UploadCourses;