import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./sidenav.scss"
import home from "../../assets/images/dashboard/home.png";
import analytics from "../../assets/images/dashboard/analytics.svg";
import partner from "../../assets/images/dashboard/partner.svg";
import documents from "../../assets/images/dashboard/documents.svg";
import royalties from "../../assets/images/dashboard/royalties.svg";
import customerservice from "../../assets/images/dashboard/customerservice.svg";
import uploaddocument from "../../assets/images/dashboard/uploaddocument.svg";
import challenge from "../../assets/images/dashboard/challanges.png";
import userlist from "../../assets/images/dashboard/userlist.svg";
import coupon from "../../assets/images/dashboard/coupon.svg";
import course from "../../assets/images/uploadcontent/course.svg";
import samples from "../../assets/images/uploadcontent/samples.svg";
import charts from "../../assets/images/uploadcontent/charts.svg";

const navLinks = [
    { image:home,url: '/home', name: 'Dashboard' },
    { image:analytics,url: '/analytics', name: 'Analytics' },
    { image:partner,url: '/partner', name: 'Partner' },
    { image:royalties,url: '/royalties', name: 'Royalties' },
    { image:documents,url: '/documents', name: 'Documents' },
    { image:customerservice,url: '/customerservice', name: 'Customer Service' },
    { image:uploaddocument,url: '/uploadcontent/course', name: 'Upload Content' ,
    submenus:[
      {image:course,url: '/uploadcontent/course', name: 'Upload Courses'},
      {image:samples,url: '/uploadcontent/samplelibrary', name: 'Sample Library'},
      {image:charts,url: '/uploadcontent/charts', name: 'Charts'}
    ]},
    { image:challenge,url: '/challenge', name: 'Challenge' },
    { image:userlist,url: '/userlist', name: 'User List' },
    { image:coupon,url: '/coupon', name: 'Coupon' }
  ];

  function SideNav(props) {  

    const [submenu,setSubmenu]=useState(false)
      
      return (      
        <div>
          <div className={props.menu?"menu":"menu active"}>               
          { <ul>
              {navLinks.map(({ url, name,image,submenus }) => (
                <li>
                 <NavLink exact to={()=>url}  className='nav-link' onClick={(e)=>url==="/uploadcontent/course"?setSubmenu(!submenu):setSubmenu(false)}  activeClassName='link-active' style={{color:"#858585"}}>
                 <img src={image} alt={name}/>
                 {name}
                 </NavLink>
                 { <ul>
                  {submenu&&submenus&&submenus.map(({ url, name,image}) => (
                   <li>
                    <NavLink exact to={()=>url} className='submenu-nav-link' activeClassName='submenu-link-active' style={{color:"#858585"}}>
                     <img src={image} alt={name}/>
                     {name}
                    </NavLink>
                   </li>
                   ))}
              </ul>
            }
                </li>
              ))}
            </ul>
            }
          </div>
        </div>
      );
    }
  
 export default SideNav;