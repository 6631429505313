
import React, { useEffect, useState }  from "react";
import "./createpartnerform.scss";
import translate from "../../../../utils/locale/language";
import Select from 'react-dropdown-select';
import {  getMethodOpen, postMethodAuthenticated } from "../../../../utils/apis/services/api";
import urls from "../../../../utils/apis/services/apiurls.json";
import { toast } from "react-toastify";
import LoadingOverlay from 'react-loading-overlay';

function CreatePartnerform(props) {

 const [error,setError]=useState('')
 
 const [genre, setGenre] = useState("");
 const [genrelist,setGenreList] = useState([]);
 const [category, setCategory] = useState("");
 const [categorylist, setCategoryList] = useState([]);
 const [state, setState] = useState({partnername:"",iban:"",affiliationlink:"",billaddress:"",password:"",email:"",bio:""});
 const [profileimage,setProfileimg] = useState(null)
 const [profile_image,setProfile_image] = useState(null)
 const [loader,setLoader]= useState(false)
 const [subgenres,setSubgenresdata] = useState([])
 const [subgenrelist,setSubGenre] = useState([])
 const [royaltydata,setRoyaltyData]=useState([])
 const [royalties,setRoyalties]=useState([])
 const [royalty,setStateRoyalty]=useState({
  action_type : "",
  amount: "",
  billing_cycle: "monthly",
  isFixed: false,
  })
 const [newroyalty,setNewRoyalty]=useState(false)
 const [royaltyindex,setRoyaltyIndex]=useState(null)
 const [selectedroyalty,setSelectedRoyalty]=useState([])

 const handleImage=async(e)=>{
  e.preventDefault();
  let formData = new FormData();
  formData.append("file", e.target.files[0]);
  setProfile_image(e.target.files[0]);
  let result = await postMethodAuthenticated(urls.fileupload, formData);
  setProfileimg(  result.data.id);
}

const handleChange = (e) => {
  const { name, value } = e.target;
  setState((prevState) => ({
    ...prevState,
    [name]: value,
  }));

setError('')
};

const billingtypes=[
  {
    name:"Monthly",
    slug:"monthly"
  }
]

const handleSubmit = async(event) => {
  event.preventDefault();
  if(genre===""||category==="")
  {
     toast.error(translate("fillrequiredfields"))
  }
  else{
      setLoader(true)
      let body={
        "royalties":selectedroyalty,
        "affiliate_link": "seedj.app/"+state.partnername,
        "billing_address": state.billaddress,
        "bio": "",
        "city": "",
        "email": state.email.toLowerCase(),
        "genre": genre,
        "name": state.partnername,
        "password": btoa("Admin@123"),
        "role": category,
        "subgenre": subgenrelist,
        "profile_image":profileimage,
        "bio":state.bio,
        "iban":state.iban
    }
    console.log(body)

    let result =await postMethodAuthenticated(urls.createpartner,body)
    console.log(result.data)
    if(result.data&&!result.data.ErrorCode&&result.status===200){
      toast.success(translate("invitationsent"))
      setLoader(false)
      setRoyalties([])
      setSelectedRoyalty([])
      setState({partnername:"",iban:"",affiliationlink:"",billaddress:"",password:"",email:"",bio:""})
      props.setSection()
    }
  else{
      setLoader(false)
      setError(result.data.Error)
      toast.error(result.data.Error)
    }
  }
};

const getGenreList=async()=>{
  let genrelist=await getMethodOpen(urls.genrelist)
  setGenreList(genrelist&&genrelist.data?genrelist.data:[])

}
const getCatogeoryList=async()=>{
  let catogeorylist=await getMethodOpen(urls.getcateogry)
  setCategoryList(catogeorylist&&catogeorylist.data?catogeorylist.data:[])
}

const setCategoryType=async (val)=>{
  setCategory(val)
  setRoyalties([])
  setSelectedRoyalty([])
  setLoader(true)
  let royaltydata=await getMethodOpen(urls.getroyaltiesdata+val+"/")
  setRoyaltyData(royaltydata&&royaltydata.data?royaltydata.data:[])
  setLoader(false)
}

const handleSubgenre =(values)=>{
  setSubGenre(values.map((item)=>{return item.slug}));
}

const handleAmount=(e)=>{
 let amount =e.target.value
royalty.action_type==="affliation-link"?setStateRoyalty((prevState)=>({...prevState,amount:amount,isFixed:false}))
 :
 setStateRoyalty((prevState)=>({...prevState,amount:amount,isFixed:true}))
 console.log(royalty)
}


const addNewRoyalty =async (e)=>{
  e.preventDefault()
  console.log(royalty.amount.slice(-1))
  if(royalty.action_type==="")
  {
    toast.error(translate("selectvalidcatogeory"))
  }
  else{  
      if(!royalties.some(i => i.action_type.includes(royalty.action_type))){  
        //checking weather array contain the id
        setRoyalties([...royalties,royalty])
        setSelectedRoyalty([...selectedroyalty,royalty])
        setNewRoyalty(false)
        toast.success(translate("royaltyadded"))
        //adding to array because value doesnt exists
      }
      else{
        // royalties.splice(royalties.indexOf(royalty), 1);  //deleting
        toast.error(translate("catogeoryalreadyselected"))
      }
      // setRoyalties([...royalties,royalty])
      

    }
    console.log(royalties)
    console.log(selectedroyalty)
}

const removeRoyalty =async (royalty)=>{
  // e.preventDefault()
        royalties.splice(royalties.indexOf(royalty), 1);  //deleting
        selectedroyalty.splice(selectedroyalty.indexOf(royalty), 1);  //deleting
        // toast.error(translate("catogeoryalreadyselected"))
    // }
    console.log(royalties)
    console.log(selectedroyalty)
}

useEffect(()=>{
  getGenreList()
  getCatogeoryList()
},[])

return(
  <LoadingOverlay
  active={loader}
  spinner
  text={translate("loadertext")}
  >
    <div>

    <h3 className="partnerheading"><b> {translate("createnewpartner")}</b></h3>    

    <div className="createpartner">

    <form onSubmit={handleSubmit}>

    <div className="createpartnerform">

    <div className="imageselector">
    <label className="custom-file-upload">
      <input type="file" onChange={handleImage} accept="image"></input>
      {/* <img src={uploadicon} class="uploadsymbol" /> */}
      <img class={profile_image ?"uploadedimage":"uploadsymbol"} src={profile_image ? URL.createObjectURL(profile_image) :"uploadicon"} alt=""/>
    </label>
    </div>

    <div className="edituserfields" style={{width:"100%"}}>

    <div className="textfieldedituser">
      <label style={{width:"15%"}}>{translate("name")}</label>
      <span className="divider"></span>
      <input placeholder="Enter partner name"  name="partnername" onChange={handleChange} required ></input>
    </div>

  <div className="flexrows">

  <div className="textfieldedituser">
      <label>{translate("affiliation")}</label>
      <span className="divider"></span>
      <input placeholder="Add affliate link" name="affiliationlink" value={"seedj.app/"+state.partnername.replace(/ /g,'')}  disabled  ></input>
  </div>

<div className="genretags">
 <span className="genreimg">
 <p>{translate("category")+": "}</p>
 </span>
  <div>
   <Select options={categorylist} labelField={"name"} valueField={"name"} onChange={(values) =>setCategoryType(values[0].slug)} className="ReactDropdownSelect" />
  </div>
</div>

</div>


<div className="flexrows">

<div className="textfieldedituser">
<label>{translate("iban")}</label>
  <span className="divider"></span>
  <input placeholder="For royalty payments" name="iban" onChange={handleChange} required ></input>
</div>

<div className="textfieldedituser">
  <label>{translate("billing")}</label>
  <span className="divider"></span>
  <input placeholder="Add billing address..." name="billaddress" onChange={handleChange} required ></input>
</div>

</div>

<div className="flexrows">

<div className="textfieldedituser">
  <label>{translate("email")}</label>
  <span className="divider"></span>
  <input placeholder="Partner E-mail" name="email" onChange={handleChange} required type="email" ></input>
</div>

{/* <div className="textfieldedituser">
<label>{translate("password")}</label>
  <span className="divider"></span>
  <input type="password" placeholder="Generate a password" name="password" onChange={handleChange} required ></input>
</div> */}

<div className="genretags">
 <span className="genreimg">
 <p>{translate("maingenre")+": "}</p>
 </span>
  <div>
   <Select options={genrelist} labelField={"genre_name"} valueField={"slug"} onChange={(values) =>{setGenre(values[0].slug);setSubgenresdata(values[0].subgenre)}} className="ReactDropdownSelect" />
  </div>
</div>

</div>


<div className="flexrows">

{/* <div className="genretagsoutside">
 <span className="genreimg">
 <p>{translate("maingenre")+": "}</p>
 </span>
  <div>
   <Select options={genrelist} labelField={"genre_name"} valueField={"slug"} onChange={(values) =>setGenre(values[0].slug)} className="ReactDropdownSelect" />
  </div>
</div> */}


<div className="textfieldedituser">
  <label>{translate("subgenre")}</label>
  <span className="divider"></span>
  <div className="dropdownselector">
       <Select multi options={subgenres} labelField={"genre_name"} valueField={"slug"} onChange={(values)=>handleSubgenre(values)} className="ReactDropdownSelect" />
  </div>
  </div>

</div>


  <div className="textareaedituser">
    <label>{translate("bio")}</label>
        <span className="divider"></span>
        <textarea placeholder="Enter something about yourself to describe you better..." name="bio" onChange={handleChange} required ></textarea>
        </div>
    </div>

  </div>









<div className="royaltiesheader" onClick={()=>(category===""||royalties.length>=royaltydata.length)?toast.error(translate("selectcategoryfirst")):setNewRoyalty(!newroyalty)}>

  <h3>{translate("royalties")}</h3>

  <p>+ {translate("addnewroyalty")}</p>

</div>

{
  newroyalty&&<div className="addroyaltyinputs">

<div className="genretagsroyalty">
 <span className="genreimg">
 <p>{translate("action")+": "}</p>
 </span>
  <div>
   <Select options={royaltydata} labelField={"name"} valueField={"slug"} onChange={(values) =>setStateRoyalty((prevState)=>({...prevState,action_type:values[0].slug}))} className="ReactDropdownSelect" />
  </div>
</div>


<div className="textfieldedituser" style={{margin:"1vw",height:"2vw"}}>
<label style={{padding:"8px"}}>{translate("amount")}</label>
  <span className="divider"></span>
  <input type="number" placeholder="Enter amount (€/%)" name="amount" max={100} onChange={handleAmount} required ></input>
</div>


<div className="genretagsroyalty">
 <span className="genreimg">
 <p>{translate("billing")+": "}</p>
 </span>
  <div>
   <Select options={billingtypes} labelField={"name"} valueField={"slug"} onChange={(values) =>setStateRoyalty((prevState)=>({...prevState,billing_cycle:"monthly"}))} className="ReactDropdownSelect" />
  </div>
</div>

<button className="addroyaltybutton" onClick={(e)=>addNewRoyalty(e)}>{translate("add")}</button>

  </div>
}

<table>

<thead>
<tr>
<th style={{width:"30%"}}>{"Action"}</th>
<th style={{width:"35%"}}>{"Amount"}</th>
<th style={{width:"35%"}}>{"Billing"}</th>
</tr>
</thead>

<tbody>
{
 royalties.map((item,index)=>{
     return <><tr className={(index===royaltyindex)&&"selectedroyalty-"} onClick={()=>{setRoyaltyIndex(index)}}>
     <td className="rowdata1">{item.action_type}</td>
     <td className="rowdata2">{item.amount}</td>
     <td className="rowdata3">{item.billing_cycle}<i class="fas fa-trash-alt" style={{float:"right"}} onClick={()=>removeRoyalty(item)}></i></td>
   </tr>
   
   </>
 })
}
</tbody>
</table>
  
  <button className="sendinvitation">{translate("sendinvitation")}</button>
  </form>
  </div>
  </div>
  </LoadingOverlay>
);

}

export default CreatePartnerform;

