import React, { useEffect, useState }   from "react";
import "./partner.scss";
import translate from "../../../utils/locale/language";
import moment from "moment";
import CreatePartnerform from "./createpartnerform/createpartnerform";
import { deleteMethodAuthenticated, getMethod, postMethodAuthenticated, putMethodAuthenticated } from "../../../utils/apis/services/api";
import urls from "../../../utils/apis/services/apiurls.json";
import { toast } from "react-toastify";
import LoadingOverlay from 'react-loading-overlay';

function Partner() {

const [partnerlist, setPartnerList] = useState([]);
const [section,setSection]=useState(2)
const [edituser,setEdituser]=useState(null)
const [state, setState] = useState({partnername:"",iban:"",billaddress:""});
const [profileimage,setProfileimg] = useState(null)
const [profile_image,setProfile_image] = useState(null)
const [loader,setLoader]=useState(false)


const getPartnersList=async()=>{
   setLoader(true)
    let partnerslist=await getMethod(urls.getpartnerslist)
    setPartnerList(partnerslist&&partnerslist.data?partnerslist.data:[])
    setLoader(false)
  }


  const handleChange = (e) => {
   const { name, value } = e.target;
   setState((prevState) => ({
     ...prevState,
     [name]: value,
   }));
  };


  const handleImage=async(e)=>{
   e.preventDefault();
   let formData = new FormData();
   formData.append("file", e.target.files[0]);
   setProfile_image(e.target.files[0]);
   let result = await postMethodAuthenticated(urls.fileupload, formData);
   setProfileimg( result.data.id);
  }

  const handleSubmit = async(item) => {
   setLoader(true)
       let body={
         "billing_address": state.billaddress,
         "name": state.partnername,
         "iban":state.iban
     }
     let imageupadte = {
      "profile_image":profileimage,
   }
   if(profileimage !== null)
   {
     Object.assign(body, imageupadte);
   }
     let result =await putMethodAuthenticated(urls.createpartner+item.id+"/",body)
     console.log(result.data)
     if(result.data&&!result.data.ErrorCode&&result.status===200){
       toast.success(result.data.message)
       setState({partnername:"",iban:"",billaddress:""})
       setEdituser(null)
       getPartnersList()
       setLoader(false)
     }
   else{
       setLoader(false)
       toast.error(result.data.Error)
     }
 };

 const deletePartner=async(item)=>{
   setLoader(true)
   let result =await deleteMethodAuthenticated(urls.createpartner+item.id+"/")
   if(result.data&&!result.data.ErrorCode&&result.status===200){
      toast.success(result.data.message)
      getPartnersList()
      setLoader(false)
    }
    else{
      setLoader(false)
      toast.error(result.data.Error)
    }
 }

 const EditUser=(item)=>{
   setEdituser(item.id)
   setState({partnername:item.full_name,iban:item.iban,billaddress:item.billing_address})
   setProfile_image(item.profile_image)
 }

useEffect(()=>{
 getPartnersList()
},[section])

return(
    <div className="partner">
      <LoadingOverlay
      active={loader}
      spinner
      text={translate("loadertext")}
      >
    <div className="pageheading" >

    <h2 >{translate("partner")}</h2>

    <div className="mainbuttons" >
       
       <button className="create" onClick={()=>setSection(1)}>{translate("create")}</button>
       <button className="bulk" onClick={()=>setSection(2)}>{translate("bulk")}</button>

    </div>

    </div>

   {/* <div>
       <CreatePartnerform/>
   </div> */}

  <div>

   {
  
  section===2
  ?
  <table>

  <thead>
  <tr>
  <th style={{width:"10%"}}>{"Select all"}</th>
  <th style={{width:"30%"}}>{"Partner"}</th>
  <th style={{width:"30%"}}>{"Active"}</th>
  <th style={{width:"30%",border:0}}>{"Type"}</th>
  </tr>
  </thead>

<tbody>
{
   partnerlist&&partnerlist.map((item,index)=>{
       return <><tr>

       <td className="rowdata1" onClick={()=>EditUser(item)}>
       <img src={item.profile_image} alt="" />
       </td>
       <td className="rowdata2" onClick={()=>EditUser(item)}>{item.full_name}</td>
       <td className="rowdata3" onClick={()=>EditUser(item)}>{moment(item.online_status.lastseen).fromNow()}</td>
       <td className="rowdata4">{item.role}<i class="fas fa-trash-alt" style={{float:"right"}} onClick={()=>!item.first_time_login?deletePartner(item):toast.error(translate("partneractive"))}></i></td>
     </tr>
     {
           (edituser===item.id)&&<div className="edituserlist">
             
            <div className="heading">
            <p>{translate("quickedit")}<b>{state.partnername} </b>{ translate("profile")}</p>
             <button className="savebutton" onClick={()=>{handleSubmit(item)}}>{translate("save")}</button>
             <button className="savebutton" style={{marginLeft:"2%"}} onClick={()=>{setEdituser(null)}}>{translate("cancel")}</button>
            </div>

            <div className="edituserdetails">

            <div className="imageselector">
            <label className="custom-file-upload">
               <input type="file" onChange={handleImage} accept="image"></input>
               {/* <img src={uploadicon} class="uploadsymbol" /> */}
               <img class={profile_image ?"uploadedimage":"uploadsymbol"} src={profile_image ? (profileimage?URL.createObjectURL(profile_image):profile_image) :"uploadicon"} alt=""/>
            </label>
            </div>

              <div className="edituserfields">

              <div className="textfieldedituser">
                <label>{translate("name")}</label>
                <span className="divider"></span>
                <input placeholder="Enter partner name"  name="partnername" onChange={handleChange} required defaultValue={state.partnername}></input>
              </div>

              <div className="flexrows">

              <div className="textfieldedituser">
                <label>{translate("iban")}</label>
                <span className="divider"></span>
                <input placeholder="For royalty payments" name="iban" defaultValue={state.iban} onChange={handleChange} required></input>
              </div>

              <div className="textfieldedituser">
                <label>{translate("billing")}</label>
                <span className="divider"></span>
                <input placeholder="Add billing address..." name="billaddress" onChange={handleChange} required defaultValue={state.billaddress}></input>
              </div>

              </div>

              {/* <div className="flexrows">

              <div className="textfieldedituser">
                <label>{translate("age")}</label>
                <span className="divider"></span>
                <input placeholder="Enter age" defaultValue={18}></input>
              </div>

              <div className="genretags">
               <span className="genreimg">
               <p>{translate("maingenre")+": "}</p>
               </span>
                <div>
                 <Select options={[]} labelField={"genre_name"} valueField={"slug"} onChange={(values) =>setGenre(values[0].slug)} className="ReactDropdownSelect" />
                </div>
             </div>

              </div> */}

              </div>

            </div>

         </div>
         }
     </>
   })
}
</tbody>
</table>
  :
  <CreatePartnerform setSection={()=>setSection(2)}/>
   }

</div>
</LoadingOverlay>
   </div>
)

}
export default Partner ;