const en = {
  home: "Home",
  library: "Library",
  exclusivesamples: "Exclusive Samples",
  monthlycharts: "Monthly Charts",
  signrequest: "Sign Requests",
  challanges: "Challanges",
  contents: "Contest",
  community: "Community",
  hello: "Hello",
  welcome: "Welcome!",
  username: "Username",
  rememberme: "Remember me",
  signin: "Sign in",
  forgotpassword: "Forgot Password?",
  signinwith: "or sign in with",
  donthaveaccount: "Don’t have an account?",
  signup: "Sign Up",
  create: "Create",
  account: "Account",
  addmusic: "Add Music",
  profile: "Profile",
  setting: "Setting",
  logout: "Logout",
  postatrack: "POST A TRACK",
  postep: "POST AN EP",
  loginsuccess: "Login successfully",
  loginerror: "Please provide correct credentials",
  signupsuccess: "Signup successfully",
  resetpassword: "Reset Password",
  title: "Title",
  genre: "Genre",
  genreplaceholder: "Add keywords fro people to find your track",
  previewimage: "Preview Image",
  preview: "Preview",
  writedescription: "Write your description here",
  monitoring: "Monitoring",
  monitoringsubheading: "Add labels to make it easier to manage and find your track",
  monitoringplaceholder: "Tag here some labels you want to like collaborate with",
  lable: "Label",
  drangdrophere: "or drag & drop here",
  next: "Next",
  fileuploadsuccess: "File uploaded successfully",
  sendlink:"send link",
  ForgotPassword:"Forgot Password",
  entervaliddetails:"Please enetr valid details",
  loadertext:'Processing...',
  enterpassword:"Enter your password",
  confirmpassword:"Confirm your password",
  login:"Login",
  subgenre:"Subgenre",
  uploadimage:"Upload image",
  upload:"Upload",
  filename:"File Name",
  duration:"Duration",
  admin:"Admin",
  userlist:"User List",
  create:"Create",
  bulk:"Bulk",
  save:"Save",
  quickedit:"Quick edit ",
  profile:"profile",
  artist:"Artist",
  city:"City",
  soundcloud:"Soundcloud",
  age:"Age",
  maingenre:"Main genre",
  email:"E-mail",
  country:"Country",
  coupon:"Coupon",
  facebook:"Facebook",
  affiliatelink:"Affiliate link",
  password:"Password",
  create:"Create",
  user:"User",
  generateautomatically:"Generate Automatically",
  sendinvitation:"Send Invitation",
  royalties:"Royalties",
  partner:"Partner",
  name:"Name",
  affiliation:"Affiliation",
  category:"Category",
  iban:"IBAN",
  billing:"Billing",
  createnewpartner:"Create new partner",
  addnewroyalty:"Add new royalty",
  invitationsent:"Invitation sent successfully.",
  uploadcontent:"Upload Content",
  course:"Courses",
  charts:"Charts",
  samplelibrary:"Sample Library",
  name:"Name",
  tutor:"Tutor",
  description:"Description",
  publicationdate:"Publication Date",
  expirationdate:"Expiration Date",
  subtitle:"Subtitle",
  language:"Language",
  duration:"Duration",
  createnewclass:"Create new class +",
  corelatedpartner:"Correlated partners ",
  nameofclass:"Name of class",
  Vimeolink:"Vimeo Link",
  resource:"Resource",
  addnextclass:"Add another class",
  addclass:"Add class",
  action:"Action",
  amount:"Amount",
  billing:"Billing",
  add:"Add +",
  entervalidamount:"Please enter valid amount and action type.",
  royaltyadded:"Royalty added successfully",
  selectcategoryfirst:"Please select cateogory first or you already added available royalties.",
  fillrequiredfields:"Please check and fill all the fields.",
  selectvalidcatogeory:"Plesae select valid action.",
  catogeoryalreadyselected:"This Royalty already added.",
  updateclass:"Update",
  update:"Update",
  author:"Author",
  bpm:"BPM",
  key:"Key",
  structure:"Structure",
  type:"Type",
  bio:"Bio",
  profileupdated:"Profile updated succesfully.",
  cancel:"Cancel",
  partneractive:"Partner is active you cannot delete it."
};
export default en;
