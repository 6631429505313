import React, { useEffect, useState }  from "react";
import "./uploadsample.scss";
import translate from "../../../../../utils/locale/language";
import Select from 'react-dropdown-select';
import { getMethod, getMethodOpen, postMethodAuthenticated} from "../../../../../utils/apis/services/api";
import urls from "../../../../../utils/apis/services/apiurls.json";
import { toast } from "react-toastify";
import LoadingOverlay from 'react-loading-overlay';
import moment from "moment";

function Uploadsample(props) {

    const [partnerlist, setPartnerList] = useState([]);
    const [selectedzipdata,setSelectedzipdata] = useState([])
    const [zip_file,setZip_file] = useState(null)
    const [loader,setLoader]= useState(false)
    const [cover_image,setCover_image] = useState(null)
    const [coverimage,setCoverimage] = useState(null)

    const [state, setState] = useState({
      samplename:props.editdata?props.editdata.name:"",
      description:props.editdata?props.editdata.description:"",
      publicationdate:props.editdata?props.editdata.publication_date:null,
      expirationdate:props.editdata?props.editdata.expiration_date:null
    });
    const [genrelist,setGenreList] = useState([]);
    const [genre, setGenre] = useState("");
    const [author, setAuthor] = useState("");
    const [is_trending,setisTrending]=useState(false)

    const getGenreList=async()=>{
        let genrelist=await getMethodOpen(urls.genrelist)
        setGenreList(genrelist&&genrelist.data?genrelist.data:[])
      }

    const getPartnersList=async()=>{
        let partnerslist=await getMethod(urls.getroles+"dj-partner")
        setPartnerList(partnerslist&&partnerslist.data?partnerslist.data:[])
      }

      const handleCoverImage=async(e)=>{
        setLoader(true)
        e.preventDefault();
        let formData = new FormData();
        formData.append("file", e.target.files[0]);
        setCover_image(e.target.files[0]);
        let result = await postMethodAuthenticated(urls.fileupload, formData);
        setCoverimage(result.data.id);
        setLoader(false)
      }

      const handleZipfile=async(e)=>{
        setLoader(true)
        e.preventDefault();
        let formData = new FormData();
        formData.append("zip", e.target.files[0]);
        setZip_file(e.target.files[0]);
        let result = await postMethodAuthenticated(urls.sampleupload, formData);
        setSelectedzipdata(result.data);
        setLoader(false)
      }

      const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
          
      };

      const handleSubmit = async(event) => {
        event.preventDefault();
        if(genre===""||author==="")
        {
           toast.error(translate("fillrequiredfields"))
        }
        else{
            setLoader(true)
            let body={
              // "content_type": "sample",
              "cover_image":coverimage,
              "name":state.samplename,
              "author":author,
              "description": state.description,
              "genre": genre,
              "publication_date":state.publicationdate,
              "expiration_date":state.expirationdate,
              "tracks":selectedzipdata.map(item=>item.id),
              "is_trending":is_trending
            }
          console.log(body)
      
          let result =await postMethodAuthenticated(urls.samplecreate,body)
          console.log(result.data)
          if(result.data&&!result.data.ErrorCode&&result.status===200){
            toast.success(result.data.message)
            props.setSection()
            setLoader(false)
          }
        else{
            setLoader(false)
            toast.error(result.data.Error)
             setLoader(false)
          }
        }
      };


      // const handleUpdate = async(event) => {
      //   event.preventDefault();
      //   if(genre===""||category==="")
      //   {
      //      toast.error(translate("fillrequiredfields"))
      //   }
      //   else{
      //       setLoader(true)
      //       let body={
      //         "id":props.editdata.id,
      //         "content_type": "course",
      //         "course_image":coverimage,
      //         "name":state.coursename,
      //         "affiliate_link": "seedj.app/"+state.coursename,
      //         "tutor": state.tutor,
      //         "description": state.description,
      //         "genre": genre,
      //         "language": state.language,
      //         "category": category,
      //         "subtitle":state.subtitle,
      //         "duration":state.duration,
      //         "publication_date":state.publicationdate,
      //         "expiration_date":state.expirationdate,
      //         "correlated_partners" : selectedlabels.map(item=>item.id),
      //         "classes":selectedclasses
      //       }
      //     console.log(body)
      //     let result =await putMethodAuthenticated(urls.uploadcontent+props.editdata.id+"/",body)
      //     console.log(result.data)
      //     if(result.data&&!result.data.ErrorCode&&result.status===200){
      //       toast.success(result.data.message)
      //       props.setedit()
      //       props.setSection()
      //       setLoader(false)
      //     }
      //   else{
      //       setLoader(false)
      //       toast.error(result.data.Error)
      //        setLoader(false)
      //     }
      //   }
      // };


      useEffect(()=>{
        getGenreList()
        getPartnersList()
      },[])

    return(
      <LoadingOverlay
      active={loader}
      spinner
      text={translate("loadertext")}
      >

        <div className="uploadsamples">

            <h2 className="heading">{translate("samplelibrary")}</h2>
            <form onSubmit={handleSubmit}>

            <div className="flexrows">

            <div className="coverimageselector">
               <label className="custom-file-upload">
               <input type="file" onChange={handleZipfile} accept="image"></input>
               <p style={{marginTop:"3vw"}}>{(zip_file||props.editdata)?'File uploaded':'Upload Zip File'}</p>
              </label>
             </div>

            <div className="coverimageselector" style={{width:"40%"}}>
               <label className="custom-file-upload">
               <input type="file" onChange={handleCoverImage} accept="image"></input>
               <img  class={cover_image ?"uploadedimage":props.editdata?"uploadedimage":"uploadsymbol"} src={cover_image ? URL.createObjectURL(cover_image) :props.editdata?props.editdata.course_image:"uploadicon"} alt=""/>
               {(cover_image||props.editdata)?'':<p style={{marginTop:"2vw"}}>Upload Photo</p>}
              </label>
             </div>

           </div>

           <div className="flexrows">
            <div className="genretags">
            <span className="genreimg">
            <p>{translate("author")+": "}</p>
            </span>
            <div>
             <Select options={partnerlist} labelField={"full_name"} valueField={"id"} onChange={(values) =>setAuthor(values[0].id)} className="ReactDropdownSelect" />
            </div>
            </div>

            <div className="genretags">
            <span className="genreimg">
            <p>{translate("genre")+": "}</p>
            </span>
            <div>
            <Select options={genrelist} labelField={"genre_name"} valueField={"slug"} onChange={(values) =>setGenre(values[0].slug)} className="ReactDropdownSelect" />
            </div>
            </div>

            <div className="custom-control custom-checkbox" style={{width:"50%",margin:"2vw"}}>
              <input type="checkbox" className="custom-control-input" id="customCheck1" defaultChecked={state.is_trending} onChange={(e)=>setisTrending(e.currentTarget.checked)} />
              <label className="custom-control-label rememberme" htmlFor="customCheck1">{"Is Trending"}</label>
            </div> 

            </div>

            <div className="textfieldedituser">
             <label>{"Name"}</label>
             <span className="divider"></span>
             <input type="text" placeholder="Enter the sample name..." name="samplename" value={state.samplename} onChange={handleChange} required ></input>
            </div>

            <div className="textareaedituser">
             <label>{translate("description")}</label>
             <span className="divider"></span>
             <textarea placeholder="Describe your video to let people know what it is…" value={state.description} name="description" onChange={handleChange} required ></textarea>
            </div>

            <div className="flexrows">

            <div className="textfieldedituser">
             <label>{translate("publicationdate")}</label>
             <span className="divider"></span>
             <input type="date" name="publicationdate" value={state.publicationdate} onChange={handleChange} min={moment(new Date()).format("yyyy-MM-D")} required ></input>
            </div>

            <div className="textfieldedituser">
             <label>{translate("expirationdate")}</label>
             <span className="divider"></span>
             <input type="date" name="expirationdate" value={state.expirationdate} onChange={handleChange} min={moment(state.publicationdate).format("yyyy-MM-D")} required ></input>
            </div>
            </div>


          {
         selectedzipdata.map((item,index)=>{
          return <div className="sampletrackscontainer">
          <div className="sampletrackstitle">
             <p className="dashline">{"---"+index}</p>
             <p style={{display:"flex"}}>{translate("filename")}:<p className="detail">{item.track_file_display_name}</p></p>
             {/* <p style={{marginLeft:"auto",display:"flex"}}>{translate("duration")}:<p className="detail">{"3:00"}</p></p> */}
           </div>

            <div className="flexrows">

            <div className="textfieldedituser">
             <label>{translate("name")}</label>
             <span className="divider"></span>
             <input type="text" name="name" value={item.track_file_display_name} disabled onChange={handleChange} required ></input>
            </div>

            <div className="textfieldedituser" style={{width:"35%"}}>
             <label>{translate("bpm")}</label>
             <span className="divider"></span>
             <input type="text" name="bpm" value={item.bmp} disabled onChange={handleChange}  ></input>
            </div>

            </div>


            <div className="flexrows">

            <div className="flexrows" style={{width:"100%"}}>

            {/* <div className="genretags" style={{margin:"1% 0"}}>
            <span className="genreimg">
            <p>{translate("type")+": "}</p>
            </span>
            <div>
             <Select options={genrelist} labelField={"genre_name"} valueField={"name"} onChange={(values) =>setGenre(values[0].slug)} className="ReactDropdownSelect" />
            </div>
            </div> */}

           <div className="textfieldedituser">
             <label>{translate("type")}</label>
             <span className="divider"></span>
             <input type="text" name="type" value={item.track_type.name} disabled onChange={handleChange}  ></input>
            </div>

            <div className="textfieldedituser">
             <label>{translate("structure")}</label>
             <span className="divider"></span>
             <input type="text" name="structure" value={item.structure} disabled onChange={handleChange}  ></input>
            </div>

            </div>

            <div className="textfieldedituser" style={{width:"35%"}}>
             <label>{translate("key")}</label>
             <span className="divider"></span>
             <input type="text" name="key" value={item.key} disabled onChange={handleChange}  ></input>
            </div>

            </div>

         </div>
         })
         }

          <button className="uploadbutton" type="submit" >{props.editdata?translate("update"):translate("upload")}</button>
         </form>
        </div>
        </LoadingOverlay>
    )

}

export default Uploadsample;