import { baseUrl,hmacKey } from "../../env.json";
import axios from "axios";
import { getToken} from "../../common.js";
import { getCurrentLocale } from "../../locale/language";

const decode = (txt) => {
  let result = atob(txt);
  return result;
};
// const token =  decode(getToken());

const lang=getCurrentLocale()

var CryptoJS = require("crypto-js");

const hmacConvert = (method, data) => {
  var string = `${hmacKey}-${method}-${data !== "" ? JSON.stringify(data) : ""}`;
  var hash = CryptoJS.HmacSHA256(string, hmacKey);
  var hashInBase64 = CryptoJS.enc.Base64.stringify(hash);
  return hashInBase64;
};

export const getMethod = async (url,token) => {
  let hKey = await hmacConvert("GET",'');

  try {
    let data = await axios({
      method: "GET",
      url: baseUrl + url ,
      headers: { "Signing-key": hKey,accept: "application/json",Authorization: "token "+decode(getToken()),"language":getCurrentLocale()},
    });
    return data;
  } catch (error) {
    return error.response;
  }
};


export const getMethodOpen = async (url) => {
  let hKey = await hmacConvert("GET",'');

  try {
    let data = await axios({
      method: "GET",
      url: baseUrl + url ,
      headers: { "Signing-key": hKey,accept: "application/json","language":getCurrentLocale()},
    });
    return data;
  } catch (error) {
    return error.response;
  }
};


export const postMethod = async (url,body,header) => {
  let hKey = await hmacConvert("POST",body);

  try {
    let data = await axios({
      method: "POST",
      url: baseUrl + url ,
      headers: header?header:{"Signing-key": hKey,"language":getCurrentLocale() ,accept: "application/json"},
      data:body
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const postMethodAuthenticated = async (url,body) => {
  console.log(url)
  let hKey = await hmacConvert("POST",body);
  try {
    let data = await axios({
      method: "POST",
      url: baseUrl + url ,
      headers:{ "Signing-key": hKey,"language":getCurrentLocale() ,"accept":"application/json", "Authorization":"token " + decode(getToken())},
      data:body
    });
    return data;
  } catch (error) {
    return error.response;
  }
};



export const postMethodAuthenticatedProgress = async (url,body,uploadProgress) => {

  let hKey = await hmacConvert("POST",body);
  try {
    let data = await axios({
      method: "POST",
      url: baseUrl + url ,
      headers:{ "Signing-key": hKey,"language":getCurrentLocale() ,"accept":"application/json", "Authorization":"token " + decode(getToken())},
      data:body,
      onUploadProgress:uploadProgress
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const putMethodAuthenticated = async (url,body) => {
  let hKey = await hmacConvert("PUT",body);
  try {
    let data = await axios({
      method: "PUT",
      url: baseUrl + url ,
      headers:{ "Signing-key": hKey,"language":getCurrentLocale() ,"accept":"application/json", "Authorization":"token " + decode(getToken())},
      data:body
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const deleteMethodAuthenticated = async (url) => {
  let hKey = await hmacConvert("DELETE",'');
  try {
    let data = await axios({
      method: "DELETE",
      url: baseUrl + url ,
      headers:{ "Signing-key": hKey,"language":getCurrentLocale() ,"accept":"application/json", "Authorization":"token " + decode(getToken())},
    });
    return data;
  } catch (error) {
    return error.response;
  }
};


